.removable-data
  overflow hidden
  transition all 500ms ease-out
  opacity 1
  min-height 26px
  position relative
  display flex
  flex-direction row
  justify-content space-between
  z-index 1

  .description-container
    display flex
    align-items center
    margin-right 8rem
    &.no-button
      margin-right auto

  .button__quantity
    margin 0
    white-space nowrap

.removable-list
  justify-content end
  padding 2rem 2rem 3rem 0
