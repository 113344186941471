fontPath ?= './fonts/'

@font-face
  font-family 'TitilliumWeb-SemiBold';
  src url(fontPath + 'TitilliumWeb/TitilliumWeb-SemiBold.woff') format('woff')
  font-weight normal
  font-style normal

@font-face
  font-family 'TitilliumWeb-Regular';
  src url(fontPath + 'TitilliumWeb/TitilliumWeb-Regular.woff') format('woff')
  font-weight normal
  font-style normal

@font-face
  font-family 'TitilliumWeb-Light';
  src url(fontPath + 'TitilliumWeb/TitilliumWeb-Light.woff') format('woff')
  font-weight normal
  font-style normal

