.amount-selector__wrapper
  position relative
  width 12.4rem
  height 3rem

.amount-selector__button
  @extend $button
  position absolute
  width 3.8rem
  height 3.6rem
  border-radius 0

.amount-selector__button-increase
  @extend .amount-selector__button
  right 0

.amount-selector__input
  position absolute
  left 3.8rem
  width 4.8rem
  height 3.6rem
  top 0.5rem
  border 1px solid $color-blue-dark-2
  font-size 1.6rem
  text-align center

