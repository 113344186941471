.modal
  position absolute
  background $color-white
  +column-layout()
    max-width 60rem
    top 30vh

.modal__header
  display flex
  align-items baseline
  justify-content space-between
  background-color $color-blue-base
  padding 1rem 1.5rem 1rem 1.5rem
  color $color-white

// Title text within header
.modal-header__title
  titillium-light-font()
  font-size 2.4rem
  margin 0
  color $color-white

.modal__body
  margin $margin-base

.modal-overlay
  position fixed
  top 0px
  left 0px
  right 0px
  bottom 0px
  background-color rgba(0, 0, 0, 0.75)
  display flex
  justify-content center
  align-items center
  z-index 301
  opacity 0
  transition opacity 150ms ease-out

.modal.warn
  .modal__header
    background-color $color-red-dark-1
    padding 1rem 2.7rem

.ReactModal__Overlay--after-open
  opacity 1

.ReactModal__Overlay--before-close
  opacity 0
