//Business panel
$business-panel-text-desc-color = rgb(67,67,67)
$business-panel-bg-color = $color-grey-light-3

$image-column-width-large = 7.8rem
$image-column-width-small = 5rem

.business-panel
  background-color transparent
  height 100%
  position relative
  z-index 9
  +min-screen($breakpoint-desktop + 1)
    width $business-panel-width-open

.business-panel__wrapper
  background-color $color-footer-bg
  +stacked-layout()
    &.open
      display block
    &.closed
      display none

  +column-layout()
    fast-width-animation()
    &.open
      width $business-panel-width-open
    &.closed
      width $business-panel-width-closed
      .business-panel
        visibility hidden