.logo__container
  z-index 10
  transition opacity 0.2s linear
  margin $margin-base $margin-half
.logo__link
  display block
  +column-layout()
    width 96px
    height 51px
  +stacked-layout()
    width 80px
    height 44px
a.logo__link
  &:focus-visible
      outline solid $color-blue-base  
      outline-offset 5px
      border-radius 0
      box-shadow none

.logo__image
  width 100%
  @media print
    width 96px
