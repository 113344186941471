
.main-toolbar-block
  padding 0.2rem 0
  margin-left 1rem

.main-toolbar__heading
  ubuntu-regular-font()
  color $color-sidebar-title
  font-size 2rem

.main-toolbar__header
  margin-bottom $margin-half
  clearfix()

  +stacked-layout()
    display none

//

.sidebar-search .main-toolbar__header
  margin-left 0.4rem

