.snapshot-container
  background-color $color-blue-light-2
  height 31rem
  width 31rem
  +stacked-layout()
    padding 0.5rem 0
    margin 0 auto

.snapshot__img
  display block
  width 310px
  height 310px
  margin 0 auto

.snapshot__loader
  margin 0 auto
  top 11rem

.snapshot__area-desc
  position absolute
  background-color rgba(255,255,255,0.7)
  margin $margin-half
  font-style italic
  padding-left 0.5rem
  padding-right 0.5rem
  width 300px

.snapshot__scale-desc
  @extends .snapshot__area-desc
  margin-top: -2.2rem;


