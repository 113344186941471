.desktop-map-controls
  +stacked-layout()
    display none
  +column-layout()
    padding 1rem 0 0 0
    width 0
    .controls-align
      position relative
      right 10rem
  .button
    width $zoom-slider-width
    background-color $color-map-overlay-bg-trans
    transition background-color 0.4s ease
    &.top
      border-bottom-left-radius 0
      border-bottom-right-radius 0
    &.bottom
      border-top-left-radius 0
      border-top-right-radius 0
    &.bottom,
    &.top
      &:focus
        background-color #ff8e2b
    .glyphicon.fa-etaisyys
      path
        transform translateX(-7%)
    &:hover
      background-color $color-grey-dark-1
      .glyphicon
        color $color-grey-light-4
    &:focus-visible
      outline none
      box-shadow 0 0 0 2px $color-orange-base inset
  .glyphicon
    color $color-white

.business-panel__controls
  position absolute
  color $color-white
  background $color-blue-base-darker
  width $business-panel-sidebar-width
  z-index 10
  height 100%
  left - $business-panel-sidebar-width

  &__content
    height 100vh
    display flex
    flex-direction column
    justify-content space-between
    align-items center

    &__first-icon
      background-color $color-blue-base-darker
      margin-top 1rem
      z-index 2

    &__last-icon
      margin-bottom 1rem
      +max-screen-height(625px)
        display none

    &__last-icon, &__first-icon
      .glyphicon
        height 2.5rem
        width 5rem
        margin 1rem 0

  &__brand
    &__container
      flex-grow 2
      position relative

    &__text
      ubuntu-regular-font()
      font-size 2.3rem
      padding 0 1rem
      color inherit
      margin 0
      transform-origin left top 0
      transform rotate(-90deg)
      position absolute
      top 38rem
      left -1.3rem

  .button
    color $color-white
    display block
    width $business-panel-sidebar-width
    border 0

    &:focus-visible
      outline solid 0 transparent
      outline-offset -5px

      .business-panel__controls__content__first-icon
        outline solid $color-white 4px
        outline-offset -5px

