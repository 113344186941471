.desktop
  .cookie-notice
    margin-right $business-panel-sidebar-width

.cookie-notice
  display flex
  justify-content space-between
  align-items center
  background $color-blue-light-3
  color $color-grey-dark-2
  position absolute
  bottom 0
  left 0
  right 0
  z-index 50
  padding $margin-half $margin-base

.cookie-notice__button
  color $color-black
  font-size 2rem