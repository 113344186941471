.nls-timeline
    list-style none
    position relative

.nls-timeline-item
    > div
        display flex
        padding 0.5em 0.25em 0 0

.nls-timeline-icon-container
    display flex
    justify-content center
    align-items flex-start
    padding-top 6px
    margin-right 6px
    width 14px
    position relative
    &::before
        content: ''
        width 0
        height 100%
        border-left 2px solid $color-blue-dark-2
        position absolute
        top 12px
        left 50%
        z-index 0
        transform translateX(-50%)

.nls-timeline-icon
    display inline-flex
    justify-content center
    align-items center
    width 14px
    height 14px
    border-radius 100%
    background-color $color-blue-dark-2
    color white
    font-size 8px
    position relative
    top -2px
    z-index 1
    &.nls-timeline-icon--present
        background-color white
        border 3px solid $color-blue-dark-2
    &.nls-timeline-icon--future
        background-color transparent
        color $color-grey-light-2
        font-size 14px
        top -3px
    &.nls-timeline-icon--cancelled
        background-color $color-blue-dark-2
        font-size 10px

.nls-timeline-text-container
    flex 1
    margin 0
    h4
        margin 0
        padding 0 0 0.25em

.nls-timeline-item--present
    background-color $color-blue-light-3
    margin 0 -0.5rem
    > div
        margin 0 0.5rem

.nls-timeline-item--future
    color $color-grey-base
    h4
        color $color-grey-base
    .nls-timeline-icon-container
        &::before
            border-left 2px dashed $color-grey-light-2

.nls-timeline-item--cancelled
    .nls-timeline-icon-container
        &::before
            border-left 2px solid $color-blue-dark-2

.nls-timeline-item:first-of-type
    .nls-timeline-icon
        border-radius 0
        width 13px
        height 13px
        &.nls-timeline-icon--future
            background-color $color-grey-light-2
            font-size 0
            width 10px
            height 10px
            top -2px
            svg
                display none

.nls-timeline-item:last-of-type
    .nls-timeline-icon-container
        &::before
            height 0

.nls-timeline-document-container
    display flex
    flex-direction column