.nls-definition-list
    container-name nls-definition-list
    container-type inline-size
    margin-top 0
    margin-bottom 0

li > .nls-definition-list
    padding-left 1.25em

.nls-definition-item
    margin-bottom 0.5em

.nls-definition-item-label
    font-weight bold

.nls-definition-item-value
     margin-inline-start 0

.nls-definition-item-value > .nls-paragraph
.nls-definition-item-value > .nls-unordered-list
.nls-definition-item-value > .nls-ordered-list
    padding-top 0.25em

@container nls-definition-list (width > 320px)
    .nls-definition-item-label
        display inline
        &::after
            content '\2002'
    .nls-definition-item-value
        display inline
