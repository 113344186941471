nls-heading()
  display block
  line-height 1.3
  margin-bottom $nls-heading-margin
  margin-top 2 * $nls-heading-margin
  color inherit
  letter-spacing -0.5px
  &:has(+ .nls-subheading)
    margin-bottom $nls-heading-margin

.nls-heading-h1
  titillium-regular-font()
  nls-heading()
  margin-bottom 3 * $nls-heading-margin
  margin-top 3 * $nls-heading-margin
  font-size 2.7rem

.nls-heading-h2
  titillium-regular-font()
  nls-heading()
  margin-bottom 2 * $nls-heading-margin
  font-size 2.3rem

.nls-heading-h3
  titillium-semibold-font()
  nls-heading()
  font-size 1.95rem

.nls-heading-h4
  titillium-semibold-font()
  nls-heading()
  font-size 1.7rem

.nls-heading-h5, .nls-heading-h6
  titillium-semibold-font()
  nls-heading()
  font-size 1.58rem

.nls-heading__with-icon
  margin-bottom 3 * $nls-heading-margin
  display flex
  color $color-heading-base
  align-items center
  :last-child
    flex-grow 1
  &.no-margin
    margin-bottom 0
  .glyphicon
    margin-right 2 * $nls-heading-margin
    align-self center
    &__h2
      font-size 40px
    &__h3
      font-size 30px
  .nls-heading-h2
    titillium-semibold-font()
    margin-top $nls-heading-margin
    margin-bottom $nls-heading-margin
    &.long-title
      font-size 1.95rem
  .nls-heading-h3
    margin-top $nls-heading-margin
    margin-bottom $nls-heading-margin

.nls-heading__with-button
  display flex
  :first-child
    flex-grow 1
  &:has(+ .nls-subheading)
    h1, h2, h3, h4, h5, h6
      margin-bottom $nls-heading-margin

.nls-subheading
  titillium-semibold-font()
  display block
  margin-top 0
  letter-spacing 0
  margin-bottom 2.5 * $nls-heading-margin
  color $color-heading-dark

.nls-realty-heading
  margin-top $nls-heading-margin
  color $color-heading-dark
  line-height 1.2
  &:has(+ .nls-subheading)
    margin-bottom 1 * $nls-heading-margin !important

.nls-eyebrow-heading
  titillium-regular-font()
  line-height 1
  display block
  margin-top 2.5 * $nls-heading-margin
  margin-bottom 0
  color $color-heading-dark

.nls-heading-h1,
.nls-heading-h2,
.nls-heading-h3,
.nls-heading-h4,
.nls-heading-h5,
.nls-heading-h6
    span:focus-visible
      outline none
      blue-focus-visible(5px)

.nls-eyebrow-heading
    span:focus-visible
      outline none
      blue-focus-visible(1px)