.skip-to
  background white
  border-radius 0 0 2px 2px
  position absolute !important
  overflow hidden
  clip rect(1px,1px,1px,1px)
  width 1px
  height 1px
  word-wrap normal
  padding 0.25em 0.5em
  z-index 100000 !important

  &:focus
    position fixed !important
    overflow visible
    clip auto
    width auto
    height auto

