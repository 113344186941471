
/*
  <input type="search" /> along with
  the submit and rest buttons
*/

.search__wrapper
  width 100%
  max-width $max-width-search-input

.search__bar
  position relative
  margin 0.25rem 0 $margin-half 0

  &.search--active
    .search-bar__reset
      display flex
    .search-bar__input
      padding-right ($width-button-search + $width-button-icon)

.search-bar__field
    display flex
    flex-direction column
    +max-screen($breakpoint-desktop)
        flex-direction column-reverse

.search-bar__label
    color white
    display inline-block
    margin-left 0.5rem
    & > .external-link
        color $color-orange-base

.search-bar__input
  display block
  width 100%
  height $height-input
  padding 0 ($width-button-search + 0.75rem) 0 0.75rem
  color $color-grey-dark-1
  background $color-white
  border 1px solid $color-blue-base
  border-radius $radius-border
  -webkit-appearance none
  &::-ms-clear
    display none
    width 0
    height 0
  &::-ms-reveal
    display none
    width 0
    height 0
  &::-webkit-search-decoration
  &::-webkit-search-cancel-button
  &::-webkit-search-results-button
  &::-webkit-search-results-decoration
    display none
  &:focus-visible
    outline none
    box-shadow 0px 0px 0px 1px $color-blue-base inset

.search-bar__reset
  display none
  position absolute
  top 0
  right $width-button-search
  height $height-input
  color $color-blue-base
  align-items center
  justify-content center
  &:hover
    color $color-blue-dark-1

.search-bar__submit
  position absolute
  top 0
  right 0
  width $width-button-search
  height $height-input
  font-size 1.25rem
  color $color-text-button
  background $color-blue-base-darker
  border-color $color-blue-base-darker
  border-radius 0 $radius-border $radius-border 0
  &:focus,
  &:hover
    background $color-blue-light-1
  &:focus-visible
    outline none
    box-shadow 0px 0px 0px 2px #fff inset

.error-on-search
  color $color-text-error

.error-on-search
  color $color-text-error

