.collapsible-toggle
  display flex
  align-items center
  justify-content space-between
  &.is-open
    .nls-badge
      display none
  div:first-child
    margin-right auto
  .nls-badge
    margin-left 0.5rem

.collapsible__content
  overflow visible

.collapsible-enter
  overflow hidden
  max-height 0
  opacity 0

.collapsible-enter-active
  overflow hidden
  transition opacity 500ms ease-out, max-height 500ms ease-out
  max-height 100rem
  opacity 1

.collapsible-exit
  overflow hidden
  max-height 100rem
  opacity 1

.collapsible-exit-active
  overflow hidden
  max-height 0
  opacity 0
  transition opacity 500ms ease-out, max-height 500ms ease-out

.collapsible-filter
  justify-content flex-end
