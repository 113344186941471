
$area-selection-button-bg-color = $color-blue-base
$area-selection-button-bg-active-color = $color-blue-dark-1

$area-selection-button-width = 7rem
$area-selection-button-height = 4.5rem

.button.button--area-selection
  width $area-selection-button-width
  height $area-selection-button-height
  color $color-white
  background-color $area-selection-button-bg-color
  border-color $area-selection-button-bg-color
  &.active
    background-color $area-selection-button-bg-active-color
    border-color $color-orange-base
  &:focus-visible
    outline none
    box-shadow 0px 0px 0px 4px $color-white inset
  .glyphicon
    width 100%
    height 100%
  .glyphicon.fa-select-map-sheet-multiple
    path
      transform scale(0.85) translate(5px, 5px)

.button.button--area-selection.map-sheet-info
  img
    height 40px
    width 66px

+pointer-states()
  .button.button--area-selection
    pointer-button-states($area-selection-button-bg-active-color, $color-white, $area-selection-button-bg-active-color, $color-white)
  .button.button--area-selection
    &.active:hover
      border-color $color-orange-base
    &:hover
      border-color $area-selection-button-bg-active-color
