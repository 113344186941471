
.mapsite-icons__container
  display flex
  flex-direction column
  justify-content flex-start
  align-items center

  .glyphicon
    height 2.5rem
    margin 0.75rem 0
  .download-open-data-icon
    height 3.5rem

  +max-screen-height(700px)
    display none