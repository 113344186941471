.snapshot__area
  overflow-x hidden
  overflow-y hidden
  border 2px solid $color-map-snapshot-border
  position absolute
  pointer-events none
  box-shadow 0 0 0 80000px $color-map-snapshot-shadow

.snapshot__area-inner
  overflow-x hidden
  overflow-y hidden
  pointer-events none
  position absolute
  border 2px solid $color-map-inner-snapshot-border
  background-color $color-map-inner-snapshot-shadow

