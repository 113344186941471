
/*
  <input type="checkbox" />
*/
$checkbox-box-size = 2.2rem
$checkbox-check-width = 1.7rem
$checkbox-check-height = 1rem
$checkbox-check-offset = 0.3rem

.checkbox-wrapper
  position relative
  input[type=checkbox]
    position absolute
    top 0.25rem
    left 0
    width $checkbox-box-size
    height $checkbox-box-size
    z-index 1
    opacity 0

.required-checkbox
  .checkbox-wrapper
    order: -1

.required-checkbox > span
    align-self center

.checkbox-content
  margin-left 3.7rem

.label--checkbox
  cursor pointer
  position relative
  min-height $checkbox-box-size
  margin 0.25rem 0 0.25rem 0
  padding 0.25rem 0 0 3.7rem
  &::before
    position absolute
    top 0
    left 0
    display block
    width $checkbox-box-size
    height $checkbox-box-size
    background $color-white
    border 1px solid $color-grey-light-1
    border-radius $radius-border
    content ""
    transition transform 0.2s, background 0.2s
  &::after
    position absolute
    top $checkbox-check-offset
    left $checkbox-check-offset
    display block
    width $checkbox-check-width
    height $checkbox-check-height
    border-bottom 2px solid white
    border-left 2px solid white
    content ""
    transform rotate(-46deg) skew(-6deg)
    visibility hidden
    transition transform 0.2s

// Active styling
.label--checkbox
  input[type=checkbox]:not([disabled]) + &:active
    &::before
      transform scale(0.8)
    &::after
      transform rotate(-46deg) skew(-6deg) scale(0.8)

// Checked styling
.label--checkbox
  input[type=checkbox]:checked + &
    &::before
      background $color-blue-dark-1
      border-color $color-blue-dark-1
    &::after
      visibility visible
      animation checkbox-check 0.2s

// Disabled styling
.label--checkbox
  input[type=checkbox][disabled] + &
    cursor auto
    color $color-grey-dark-2
    &::before
      border-color $color-grey-light-2
      background-color $color-grey-light-4

  input[type=checkbox][disabled]:checked + &
    &::before
      background $color-grey-light-2
      border-color $color-grey-light-2
    &::after
      border-color $color-white

// Focus styling
.label--checkbox
  input[type=checkbox]:focus + &::before
    box-shadow 0 0 0 0.3rem $color-blue-base

// Error styling
.label--checkbox.error
  &::before
    background $color-input-error-background
    border-color $color-input-error-border

// Hover styling
+pointer-states()
  input[type=checkbox]:not([disabled]):not(:checked) + .label--checkbox
    &:hover
      &::before
        box-shadow inset 0 0 6px -1px $color-grey-light-2
  input[type=checkbox]:not([disabled]):checked + .label--checkbox
    &:hover
      &::before
        background $color-blue-dark-2
      &::after
        border-color $color-grey-light-3


@keyframes checkbox-check {
  0% {
    top: $checkbox-check-offset + 0.7rem;
    width: 0;
    height 0;
  }
  40% {
    top: $checkbox-check-offset + 0.2rem;
    height: $checkbox-check-height;
  }
  100% {
    top: $checkbox-check-offset;
    width: $checkbox-check-width;
  }
}
