$zoom-slider-height = 17rem
$zoom-slider-handle-width = 2.1rem
$zoom-slider-handle-width-offset = 1rem

.map-zoom-controls
  background-color $color-map-overlay-bg-trans
  position relative
  width $zoom-slider-width
  margin $margin-base 0
  padding $margin-half 0
  border-radius 2px
  .button
    background none
    border-radius 0

.map-tracking-controls
  margin $margin-base 0

.hidden-beta
  opacity 0
  margin $margin-base 0
  button
    cursor: unset !important


.zoom-slider
  height $zoom-slider-height
  margin $margin-half 0

  &.rc-slider-vertical
    padding 0

  .rc-slider-step
    width 100%
    display flex
    justify-content center

    &:after
      content: ''
      width 1px
      background-color $color-grey-light-1

  .rc-slider-handle
    border-radius 5px
    border-color $color-white
    height 1rem
    width $zoom-slider-handle-width
    left $zoom-slider-handle-width-offset
    &:focus-visible
      outline none
      box-shadow 0px 0px 0px 3px $color-orange-base
