$desktop-column-width = $business-panel-width-open / 2
$mobile-column-width = 100%
$scroller-column-background = $color-white

.scroller-container
  position relative
  overflow hidden

.mobile
  // Firefox requires that this is only set in mobile
  .scroller-container, .scroller-panel-column__content-positioner
    width $mobile-column-width

.scroller
  transition transform 0.35s cubic-bezier(0.4, 0.01, 0.165, 0.99)
  height 100%
  display flex
  .scroller-panel__column
    position relative
    overflow hidden
    &:nth-child(odd)
      background $color-panel-list-white-bg
      &::after
        background $color-panel-list-blue-bg
    &:nth-child(even)
      background $color-panel-list-blue-bg
      &::after
        background $color-panel-list-white-bg
    &.hidden-column
      height 0
      visibility hidden
  &:not(.full-width)
    .scroller-panel__column
      +column-layout()
        &:not(.last-column)
          display flex
          &::after
            content ''
            width 2rem
            height 100%
            border-left 2.5px solid $color-blue-light-2

.scroller-panel-column__content-positioner
  display table // used to make positioner tightly wrap its contents instead of expanding down
  position relative
  width 100%
  &:first-child
    .scroller-panel__content
      padding-top 2rem
      &:not(:first-child)
        padding-top 0

.full-width
  .scroller-panel__page
      padding 2rem 2rem 3.5rem 2.3rem
.scroller-panel__page
  padding 2rem 1.3rem 3.5rem 1.3rem
.scroller-panel__content
  padding 0rem 1.3rem 1.3rem 1.3rem

.scroller-panel-column__content-scroll-button
  position fixed
  top 15rem
  right 15rem
  z-index 1
  border-radius 50%
  box-shadow 2px 2px 5px 1px rgba(0,0,0,0.7)
  .glyphicon
    margin-right 3px;
    margin-top 3px;

.scroller-column-content__button
  transition opacity 500ms
  opacity 0

.scroller-column-content__button-entered
  opacity 1
  

.desktop .scroller:not(.full-width)
  /*
    This for-loop generates 6 .column-{number} classes where the margins are 
    0 for the first two and then go down incrementally.
  */
  for col in (1..6)
    &.column-{col}
      if col == 1
        transform translateX(0)
      else 
        transform translateX($desktop-column-width * -(col - 2))

  .scroller-panel__column
    &.scroller-panel__column--single
      flex 0 0 $desktop-column-width
    &.two-columns
      flex 0 0 $desktop-column-width * 2

  .scroller-panel__column .two-columns
    flex 0 0 $desktop-column-width

.mobile .scroller, .scroller.full-width
  display flex
  width 100%
  /*
    This for-loop generates 6 .column-{number} classes where the margins go down
    from 0 to -5 times the column width
  */
  for col in (1..6)
    &.column-{col}
      transform translateX($mobile-column-width * -(col - 1))

  .scroller-panel__column
    flex 0 0 ($mobile-column-width)
