.nls-ordered-list
    list-style none
    counter-reset section
    padding 0
    margin-top 0
    margin-bottom 0

li > .nls-ordered-list
    padding-left 1.25em

.nls-ordered-item
    padding-bottom 0.25em
    padding-left 1.25em
    position relative
    &:last-of-type
        padding-bottom 0
    &::before
        counter-increment section
        content counter(section)'.'
        text-align left
        display inline-block
        position absolute
        left 0
        width 1.25em

.nls-ordered-list--wide-indent
    .nls-ordered-item
        padding-left 1.75em
        &::before
            width 1.75em
