.form__container-bg
  padding 1rem 2rem 5rem 1.5rem
  background-color $color-bg-base
  &.loading
    padding-bottom 9rem

.heading-row
  &:focus
    outline none

.header-title
  &:focus-visible
    outline none
    .header-title__text
      blue-focus-visible(3px)

.info-row__with-button
  display flex
  align-items baseline
  justify-content space-between

.navigation__block
  display flex
  justify-content space-between

.description-summary
  overflow-wrap break-word
  +min-screen($breakpoint-desktop + 1)
    max-width "calc(%s - %s)" % ($business-panel-width-open $business-panel-sidebar-width)
  +max-screen($breakpoint-desktop)
    max-width 100%

.attachments-filename
  word-break break-word
