// Z-index range: 1-10
.map-container
  background-color $color-white  
  position relative
  display inline-block
  height 100%
  overflow hidden
  width 100%
  +min-screen($breakpoint-desktop + 1)
    width "calc(100% - %s)" % $business-panel-sidebar-width

  .back-button
    position absolute
    top 0.5rem
    right 1rem

.mock-map
  position absolute
  top 0
  bottom 0
  left 0
  right 0
  width 100%
  height 100%
  background-color lightblue

.desktop .back-button
  display none

.map-iframe
  background-color $color-white
  box-sizing border-box
  margin 0
  height 100%
  border 0
  width 100%
  display block

.oskari-map-veil
  background-color $color-grey-light-4
  position absolute
  top 0
  right 0
  left 0
  bottom 0
  display flex
  flex-direction column
  justify-content center
  align-items center
  .glyphicon
    color $color-blue-dark-2
    font-size 4rem

&.disable-events
  pointer-events none
