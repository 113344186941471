.nls-unordered-list
    list-style-type none
    padding-left 0
    margin-top 0
    margin-bottom 0

li > .nls-unordered-list
    padding-left 1.25em

.nls-unordered-item
    padding-bottom 0.25em
    &:last-of-type
        padding-bottom 0

.nls-unordered-list--bullets
    .nls-unordered-item
        padding-left 1.25em
        position relative
        &::before
            content '–'
            text-align left
            display inline-block
            position absolute
            left 0
            width 1.25em
