.file-download-service__product-properties__preview-img
  height 187px

.file-download-service__info-box
  display flex
  align-items center
  padding 0.5rem
  border 2px solid $color-blue-light-1

.file-download-service__info-box__icon
  padding-right 0.5rem
  font-size 2rem
  color $color-blue-light-1

.file-download-service__product-list__heading
  padding-left 4rem
  background-size 30px
  background-repeat no-repeat
  min-height 3.5rem
  padding-top 0.6rem
  margin-bottom 0

.file-download-service__product-list__raster
  height 100%
  background-image url(rootPath + '/img/karttapaikka-rasteri.svg')
  +max-screen(410px)
    padding-top 0

.file-download-service__product-list__vector
  background-image url(rootPath + '/img/karttapaikka-vektori.svg')

.file-download-service__product-list__restricted
  background-image url(rootPath + '/img/karttapaikka-kayttorajoitettu.svg')
