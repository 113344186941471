
/*
  <label />
*/


.label
  ubuntu-medium-font()
  display block
  color $color-grey-dark-2

.label--text-input
  margin-top $margin-half

.label--checkbox,
.label--radio
  ubuntu-regular-font()
