
/*
  Wrap your .grid-block(s) in .grid-row(s) and
  your .grid-row(s) in a .flex-grid-container
*/

.flex-grid-container
  padding 0 $grid-gutter

.grid-row
  clearfix()
  margin 0 (-1 * $grid-gutter)
  &.with-button-top-right
    .content-header
      margin-right 5rem

.grid-block
  float left
  min-height 1px
  padding 0 $grid-gutter
  &.panel-list-adjust
    margin-left -0.7rem

/*
  The following three for-loops produce the widths in percentages
  for the grid columns.

  At the time of writing, our layout is a 6 column one, so
  you'll get class names from .grid-block-1 to .grid-block-6,
  from .mobile-block-1 to .mobile-block-6, and
  .desktop-block-1 to .desktop-block-6
*/

for col in (1..$grid-max-columns)
  .grid-block-{col}
    width percentage(col / $grid-max-columns)

for col in (1..$grid-max-columns)
  +max-screen($breakpoint-desktop)
    .mobile-block-{col}
      width percentage(col / $grid-max-columns)

for col in (1..$grid-max-columns)
  +min-screen($breakpoint-desktop + 1)
    .desktop-block-{col}
      width percentage(col / $grid-max-columns)

.layout
  width 100%
  height 100%
  +column-layout()
    display flex
    .desktop__left-view, .desktop__middle-view, .desktop__right-view
      vertical-align top
      position relative
      height 100%
      width 100%
    .desktop__right-view
      order: 3
      z-index: 20
  +stacked-layout()
    display block
    .desktop__right-view, .desktop__left-view
      display none
    .mobile-top-view, .mobile-main-view,.mobile-bottom-view, .mobile-map-view
      position absolute
      width 100%

.layout-veil
  background-color $color-grey-dark-3
  position absolute
  top 0
  right 0
  left 0
  bottom 0
  z-index 10001
  .spinner__block
    align-items center
    height 100%

// // .layout.mobile
//  // =>
.mobile-top-view
  +stacked-layout()
    z-index 300
  +column-layout()
    display none

.mobile-bottom-view
  +stacked-layout()
    height $bottom-toolbar-height
    z-index 21
    bottom 0
  +column-layout()
    display none

.mobile-map-view
  +stacked-layout()
    top $main-toolbar-height
    bottom $bottom-toolbar-height

.mobile-main-view
  +stacked-layout()
    top $main-toolbar-height

    //Main view states, static and curtain-controlled
    &.main-view-content
      bottom $bottom-toolbar-height

    &.main-view-full
      bottom 0
      z-index 22

    &.main-view-curtain-half
      fast-all-animation()
      height 65%

    &.main-view-curtain-hang
      fast-all-animation()
      height calc(100% - 22rem )
      +max-screen-height(180px)
        height calc(100% - 12rem )

    &.main-view-curtain-closed
      fast-all-animation()
      height 9.7rem

//Mobile views that must overlap with map
.mobile-menu-view
  z-index 200

