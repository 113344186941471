
/*
  These mixins will provide you with the
  correct font weight and style.
*/

titillium-semibold-font()
  font-family 'TitilliumWeb-Semibold', sans-serif
  font-style normal
  font-weight normal

titillium-regular-font()
  font-family 'TitilliumWeb-Regular', sans-serif
  font-style normal
  font-weight normal

titillium-light-font()
  font-family 'TitilliumWeb-Light'

ubuntu-regular-font()
  font-family 'Ubuntu', sans-serif
  font-style normal
  font-weight normal

ubuntu-medium-font()
  font-family 'Ubuntu', sans-serif
  font-style normal
  font-weight 500