$curtain-handle-icon-color = $color-blue-base
$curtain-handle-bg-color = alpha($color-grey-base, 0.9)

.curtain__handle
  display none
  align-items center
  justify-content center
  height 2.5rem
  background $curtain-handle-bg-color
  color $curtain-handle-icon-color
  cursor pointer
  width 100%
  border none
  .glyphicon
    font-size 3rem

.curtain
  .curtain__handle
    display flex
  .business-panel
    overflow hidden