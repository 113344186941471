
/*
  <input type="radio" />
*/
$radio-outline-size = 21px
$radio-mark-size = 11px
$radio-mark-offset = 0.5rem

.radio-wrapper
  position relative
  input[type=radio]
    position absolute
    top: 0.25rem
    left 0
    width $radio-outline-size
    height $radio-outline-size
    z-index 1
    opacity 0

.radio-wrapper__extra-block
  padding-left 3.7rem

.radio-group
  > .radio-wrapper
    &:not(:last-child)
      margin-bottom $margin-base

.label--radio
  position relative
  min-height $radio-outline-size
  margin 0.25rem 1rem 0.25rem 0
  padding 0.1875rem 1rem 0 3.7rem
  &.label
    display inline-block
  &::before
    position absolute
    top 0
    left 0
    display block
    width $radio-outline-size
    height $radio-outline-size
    background-color $color-white
    border 1px solid $color-grey-light-1
    border-radius 50%
    content ""
    transition transform 0.2s
  &::after
    position absolute
    top $radio-mark-offset
    left $radio-mark-offset
    display block
    width $radio-mark-size
    height $radio-mark-size
    background $color-blue-dark-1
    border-radius 50%
    content ""
    visibility hidden
    transition transform 0.2s

.label--radio
  input[type=radio]:checked + &
    &::after
      visibility visible

// bold label for checked radio button
.bold-checked
  input[type=radio]:checked + &
    font-weight bold

.label--radio
  input[type=radio]:focus + &::before
    box-shadow 0 0 0 0.3rem $color-blue-base

// Active styling
.label--radio
  input[type=radio]:not([disabled]) + &:active
    &::before, &::after
      transform scale(0.8)

// Disabled styling
.label--radio
  input[type=radio][disabled] + &
    cursor auto
    color $color-grey-dark-2
    &::before
      border-color $color-grey-light-2
      background-color $color-grey-light-4

  input[type=radio][disabled]:checked + &
    &::before
      background $color-grey-light-2
      border-color $color-grey-light-2
    &::after
      border-color $color-white

// Error styling
.label--radio.error
  &::before
    background $color-input-error-background
    border-color $color-input-error-border

+pointer-states()
  input[type=radio]:not([disabled]):not(:checked) + .label--radio
    &:hover
      cursor pointer
      &::before
        box-shadow inset 0 0 6px -1px $color-grey-light-2
  input[type=radio]:not([disabled]):checked + .label--radio
    &:hover
      cursor pointer
      &::after
        background $color-blue-dark-2

.radio--description
  color $color-grey-base
  margin 0 0 1rem 3.8rem

.radio--nested-description
  color $color-grey-base
  margin 0 0 1rem 0

.radio-wrapper
  width 100%
