$panel-list-odd-bg = $color-panel-list-white-bg
$panel-list-even-bg = $color-panel-list-blue-bg
$panel-list-odd-active-item-bg = $color-panel-list-blue-bg
$panel-list-even-active-item-bg = $color-panel-list-white-bg
$panel-list-item-desc-color = $color-text-base
$panel-list-item-status-color = $color-red-dark-1
$panel-list-item-register-unit-pin-icon-color = $color-red-additional
$panel-list-item-facility-pin-icon-color = $color-blue-additional
$panel-list-item-apartment-pin-icon-color = $color-apartments
$panel-list-item-property-pin-icon-color = $color-orange-dark-1
$panel-list-item-usufruct-unit-pin-icon-color = $color-turquoise

$panel-border-size = 2.5px
$panel-list-top-padding = 2rem
$panel-content-heading-min-width = 225px;
$desktop-column-width = $business-panel-width-open / 2 // TODO: move to shared

gradient-hover(color)
  background linear-gradient(to right, rgba(255,255,255,0) 58%, color 97%)
  background-size 300% 100%
  background-position left bottom
  transition background 0.4s linear
  &:hover
    background-position right bottom

lifeline-color(color)
  .panel-list__lifeline
    .lifeline__line
      background-color color
    .glyphicon
      color color
  .panel-content__heading
    color color

.real-estate-section-selector
  .panel-list__item
    &:first-of-type
      margin-top 4px
    &:not(.selected)
      z-index 10000
    .panel-list-item__link
      &:hover
        .panel-list-item__content
          margin-left 0px

/*
  Actual styles start here
*/
.panel-list__wrapper
  position relative
  &:last-child
    margin-bottom 5rem
  &.panel-list__adjusted
    margin 0 0.5rem
.scroller:not(.full-width)
  .panel-list__wrapper
    +column-layout()
      width $desktop-column-width - 1.5rem // To force the panel to keep it's shape through transitions in desktop
.karttapaikka #scroller-panel-column-1 .panel-list__wrapper:last-child
  margin-bottom 0

.panel-list__wrapper&:first-of-type, .panel-list__lifeline +
  .panel__list
    padding-top $panel-list-top-padding

.panel__list
  padding 0 0 3px 0
  margin 0
  list-style none
  .loader
    display flex
    justify-content space-around
    align-items: center
    height 7rem
    margin-left 0 !important
.karttapaikka #scroller-panel-column-1 .panel__list
  padding-top 3px

.panel-list__item
  margin-left $margin-base
  position relative
  transform translateX(3px)
  z-index 10
  margin-bottom 2px
  word-break break-word
  &.mortgage-list__item
    margin-left 0.5rem
  &.mortgage-list__item&.selected, &.usufruct-list__item&.selected, &.realty-list__item__with-link&.selected:not(:last-child)
    margin-top 0
    margin-bottom 4px !important
  &.frame&.selected
    margin-top 0px
    margin-bottom 2px
  &.selected, &:has(+ .selected)
    z-index 1
    .panel-list-item__divider
      background-color transparent
    .with-divider
      border-color transparent!important
  
  .mortgage-list-item
    word-wrap break-word

  .active
    // this value added further down only for even columns
    // left -0.5rem
    position absolute
    top -2px
    border 3px solid $color-blue-border-light
    border-radius 3px 0 0 3px
    height calc(100% + 4px)
    width 100%
    z-index -1
.panel-list-item__link, .panel-list-item__no-link:not(.lifeline-title), .panel-list-item__notice
  position relative
  text-decoration none
  display block
  width calc(100% - 5px)
  padding-left 2px
  margin-top 2px
  clearfix()
.panel-list-item-link__icon
  position absolute
  right 0.5rem
  top 0
  height 100%
  font-size 1.5rem
  color $color-link
  margin-right $margin-half
  transition right 0.3s
.panel-list-item__content
  position relative
  margin-right 2rem
  margin-bottom -2px
  &.with-divider
    border-bottom 2px solid #d4e8f0
  a
    text-decoration underline
  h4
    &:hover
      text-decoration none
  .panel-content__heading
    min-width $panel-content-heading-min-width
.panel-list-item-title__wrapper
  display flex
  justify-content space-between
  align-items center
.panel-list-item__title
  margin-bottom 1.5rem
  font-size 2.1rem
.panel-list-item-title__with-icon
  display flex
  :last-child
    flex-grow 1
  .glyphicon
    color $color-heading-base
    font-size 4rem
    margin-right 1.5rem
    align-self center
.panel-list-item__title--sub
  font-size 1.8rem
  margin-bottom $margin-base

.last-column
  .panel-list__wrapper
    margin-left 1rem
    .panel-list__item
      margin-left 0

.scroller.full-width, .mobile
  .last-column
    .panel-list__wrapper
      margin-left 0
      .panel__list
        padding-left 1rem
        padding-right 1rem
    .panel-list__item:not(.panel-list-adjust)
      margin 0 $margin-base
      .panel-list-item__divider
        margin 0 0 0 -0.5rem
    .panel-list-item.panel-list-adjust
      margin 0 2.5 * $margin-base 0 $margin-base
    .panel-list-item__divider
      margin 0 2 * $margin-base - 0.3rem 0 2 * $margin-base + 0.3rem
    .panel-list-item__content
      margin-right 0
    .panel-list-item__content.panel-list-adjust
      margin 0 1.5 * $margin-base 0 $margin-base
      .panel-list-item__divider
        margin 0 -1.5rem 0 0

.panel-list__item:not(.panel-list-adjust)
  .panel-list-item__divider
    margin-left -0.4rem

.panel-list-item__divider
  height 2px
  background-color $color-blue-border-light
  margin 0 1.5rem

.panel-list-item__divider.activity-item-divider
  margin-right 0.4rem
  margin-left 0;

.panel-list__item.frame, &.selected
  &:first-of-type
    .panel-list-item__link, .panel-list-item__no-link
      border-top 2px solid #aad3e0
      margin-top 0
  &:last-of-type
    margin-bottom 1.5rem!important
    .panel-list-item__link, .panel-list-item__no-link
      border-bottom 2px solid #aad3e0

.panel-list__item:last-of-type
  .panel-list-item__content
    border none
  .panel-list-item__divider:not(.keep-last-divider)
    visibility hidden

.panel-list-item__wrapper.with-link
  .panel-list-item__divider
    margin-left: 69px

.panel-list-item__status
.panel-list-item__downloadable
  color $panel-list-item-status-color
  margin-top 0.75rem
  .panel-list-item__status-icon
    margin 0 0.5rem 0 0
  .panel-list-item__downloadable-text
    color $color-green-dark-1

.panel-list-item__status
  ubuntu-medium-font()

.search-list__divider
  height 2px
  background-color $color-blue-light-2
  margin 0 2rem 0 7rem

.panel-list__lifeline
  padding-top $panel-list-top-padding + 0.2rem
  margin-left $margin-base * 1
  display flex
  flex-direction column
  align-items center
  position absolute
  height 100%
  .lifeline__line
    width 2px
    height calc(100% - 5.5rem)
  .glyphicon
    font-size 4rem

.real-estate-list-item__lifeline-icon
  top 0.3rem
  display flex
  align-items center
  height 5rem
  .glyphicon
    font-size 3.2rem
.real-estate-list-item__lifeline-icon--blue
  color $panel-list-item-facility-pin-icon-color
.real-estate-list-item__lifeline-icon--green
  color $panel-list-item-apartment-pin-icon-color
.real-estate-list-item__lifeline-icon--red
  color $panel-list-item-register-unit-pin-icon-color
.real-estate-list-item__lifeline-icon--orange
  color $panel-list-item-property-pin-icon-color
.real-estate-list-item__lifeline-icon--turquoise
  color $color-turquoise
  stroke $color-dark-turquoise
.real-estate-list-item__lifeline-icon--purple
  color $color-purple-light
  stroke $color-purple-dark

.list-item__calculator
  border 1px solid
  border-radius 15px
  padding 0.5rem 0.75rem

/*
  Column coloring
  Every other column has inverse colors from the columns next to it.
*/
.panel__list--odd
  background-color $panel-list-odd-active-item-bg
  .panel-list-adjust
    .panel-list__item
      .active
        border-right-color white
  .panel-list__item
    .active
      background-color $panel-list-odd-active-item-bg
      border-right-color $panel-list-odd-active-item-bg
      + .panel-list-item__link, + .panel-list-item__no-link
          border-color transparent
          margin-top 0
          margin-bottom 0
        .real-estate-list-item__lifeline-icon
          background-color $panel-list-odd-active-item-bg
  .panel__list
    background $panel-list-odd-bg
    .real-estate-list-item__lifeline-icon
      background-color $panel-list-odd-bg

.panel__list--even
  background-color $panel-list-even-active-item-bg
  .panel-list-adjust
    .panel-list__item
      margin-left 0
      .active
        border-right-color white
  .panel-list__item
    .active
      left -0.5rem
      background-color $panel-list-even-active-item-bg
      border-right-color $panel-list-even-active-item-bg
      + .panel-list-item__link
        .real-estate-list-item__lifeline-icon
          background-color $panel-list-even-active-item-bg
      + .panel-list-item__link
          border-color transparent
          background transparent !important
    .realty-item-even
      left 0 !important
  .panel__list
    background $panel-list-even-bg
    .real-estate-list-item__lifeline-icon
      background-color $panel-list-even-bg


/*
  Column-specific styles
*/
.real-estate-section-selector
  &.panel-list__wrapper
    +min-screen($breakpoint-desktop + 1)
      margin-bottom 11.4rem
  .panel-content__heading
    align-self center
  .real-estate-section-selector__content
    display: flex;
    flex-direction: column;
    padding 2.5rem
  .panel-list__item .panel-list-item__link .panel-list-item-link__icon
    font-size 2rem

.real-estates, .apartments, .activity-log
  div.lifeline-title
    margin-left 5.5rem
  .active
    background $panel-list-even-active-item-bg
    width calc(100% + 0.5rem) !important

.real-estates, .apartments
  .error__block
    margin-left 5.5rem
  .panel-list-item__divider
    margin 0 0 0 5.5rem!important
  .info-item, .api-error
    padding-left 2.75rem
  .no-items
    padding-left 5.5rem

.activity-log
  lifeline-color($color-activity-log-color)
  .panel-list-item__content
    margin-left 5.5rem

.real-estates
  lifeline-color($color-real-estates-color)
  .panel-list-item__title
    color $color-real-estates-color
  .spinner__block
    margin-left 5.5rem
  .panel-list-item__checkbox
    display flex
    align-items flex-start
    margin-bottom 1.5rem
    padding 0.5rem 0.25rem 0.5rem 0
  @media (min-width 600px) and (max-width 820px)
    .panel-list-item__checkbox
      align-items center
    

.apartments
  lifeline-color($color-apartments-color)
  .panel-list-item__title
    color $color-apartments-color
  .spinner__block
    margin-left 5.5rem

.panel-list-item__title
    outline none
    span:focus-visible
      blue-focus-visible(5px)

.panel-list-adjust
  .panel-list-item__link
    width calc(100% - 10px)
    padding-left 0
  .panel-list-item__divider
    margin 0

+pointer-states()
  .active + .panel-list-item__link
    &:hover
      background transparent !important
  .panel-list-item__link
    &:hover
      text-decoration none
      .panel-list-item-link__icon
        right 1rem
  .panel__list--odd
    .panel-list-item__link
      &:hover
        gradient-hover($panel-list-odd-active-item-bg)
        z-index 1
  .panel__list--even
    .panel-list-item__link
      &:hover
        gradient-hover($panel-list-even-active-item-bg)


/*
  Styles specific to contents within panel list items
*/
.panel-content__heading
  titillium-semibold-font()
  margin 0
  text-align center
  color $color-heading-base
  font-size 1.8rem
  &:hover
    text-decoration none
.longer-title
  font-size 1.8rem

.panel-content__text
  text-align center
  color $panel-list-item-desc-color

.panel-list-item-content__title
  ubuntu-medium-font()
  color $color-link
  font-size 1.5rem
  margin-bottom 0.25rem
  margin-right $margin-base
  display flex
  flex-direction row
  width 100%
  justify-content space-between
  align-items center

.panel-list-item-content__name
  ubuntu-medium-font()

.panel-list-item-content__description
  margin-right 1rem

.realty-panel__content
  margin-top 2rem

.realty-item
  padding 0.5rem 0.5rem 0 0.5rem

.realty-item-header__button
  align-self flex-start
  margin-top 2 * $nls-heading-margin

.realty-item__header
  display flex
  justify-content space-between
  flex-wrap nowrap
  align-items center
  word-break break-word
  .heading
    flex 1

.realty-item__with-link
  list-style none
  .realty-item__with-link-heading
    padding 2.5rem 3.8rem 0 1.8rem
    #realty-item-title
      display block
  .realty-item__with-link-text
    padding 0 3.8rem 0.6rem 1.8rem
    span
      padding 0 3.8rem 0 1.8rem

.realty-item-odd
  background: $color-white
.realty-item-even
  background: $color-blue-light-3

.realty-item__heading
  margin 0

.panel-link__even
  .active
    background-color white
  &:hover
    gradient-hover($panel-list-even-active-item-bg)

.realty-item__heading-identifier
  font-size 2.1rem

.real-estate-action-link
  padding 1.6rem 1.5rem 1.3rem

.realty-item-content
  position relative
  padding 1rem 0
  .realty-list-item-wrapper
    display flex
    flex-direction column
    justify-content center
  .realty-list-item-wrapper__with-icon
    margin-left: 2.5rem

.location-contents-wrapper
  .location-contents-title
    color $color-grey-dark-2
    font-family inherit
    font-size inherit
    font-weight bold
    margin-left 1.8rem
    margin-top 0.7rem
    &.without-toggle
      margin-bottom 0.7rem
  .location-contents-subtitle
    margin-left 1.8rem
  .location-items-list
    margin-bottom 0
    .panel__list
      padding-top 0rem
    .realty-list__item__with-link
      margin-top 0rem

.location-items-list
  .panel-list-item__content
    margin-right 0.5rem
  .location-item-content
    display flex
    align-items flex-start
    padding 1rem 0 1.25rem
    &.with-divider
      border-bottom 2px solid #d4e8f0
    .location-item__real-estate-icon
      font-size 3.2rem
      padding 0 0.5rem
    .error-icon
      font-size 2rem
      color $color-red-dark-1
      margin 0 0.5rem
    .panel-list-item-content__title
      color $color-grey-dark-2
  .panel-list-item__link, .panel-list-item__no-link, .panel-list-item__notice
    .location-item-content
      border-top none
      border-bottom default
      .panel-list-item-content__title
        color $color-link
    &.active-menu-item
      .location-item-content
        border-bottom none

.activity-item-row-wrapper
  padding 0 0 1rem 0.3rem

.scroller:not(.full-width)
  .activity-item-rows
    margin-right -1rem

.panel-list-item__content.with-link
  .realty-item-content
    display flex

.panel-list-item__content,
.panel-list-item-title__with-icon,
//.realty-item
  h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5
    display block
    span
      display inline-block
      line-height 100%
    &:focus-visible
      outline none
      span
        blue-focus-visible(5px)

@media (max-width 630px)
  .mobile-item-heading
    flex 1 0 70%
    hyphens auto

.panel-list-item-notification-text
  color $color-orange-dark-1

.supplement-request-content
  white-space pre-wrap

.supplement-request-status
  border $color-grey-light-1
  border-style solid
  padding 1rem
  background-color $color-white

.suppplement-request-header
  display flex
  align-items center
  justify-content space-between

.supplement-request-print
  width 100%
  height 100%
  padding 1rem
  background $color-panel-list-white-bg
  color $color-text-base
  list-style none
  overflow-x hidden
  overflow-y auto
  .supplement-request-print-content
    margin-bottom 9rem
    white-space pre-wrap
    clearfix()
  .supplement-request-print-header
    padding 0 0.625rem
  .supplement-request-print-buttons
    margin 4rem 0 0 1rem
    position fixed
    bottom 0
    background $color-panel-list-white-bg
    z-index 9999
    display flex
    width 95%
    height 9rem
  .supplement-request-cancel-button
    padding-right calc(5% + 2rem)
  @media print
    .supplement-disclaimer-block
      width 80% !important
    .infobox__content
      margin 1.75rem !important
    section
      margin 1rem 0 !important
    .supplement-request-print-buttons 
      display none !important
  @media (min-width 375px)
    .supplement-request-print-buttons
      align-items center
  @media (max-width 750px)
    .supplement-request-print-buttons
      justify-content space-between
  



