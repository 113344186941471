$sidebar-bg-color = linear-gradient(110deg, $color-grey-dark-3 20%, $color-grey-dark-2 100%)
$open-sidebar-bg-color = linear-gradient(125deg, $color-grey-dark-1, #000 135%)
$opening-transition-length = 0.2s

.sidebar__wrapper
  padding 0px 0.625rem
  display grid
  grid-template-columns auto
  grid-auto-rows max-content

.sidebar
  position relative
  z-index 1 /* this prevents middleview content from flowing over the sidebar in map store view*/
  background-color $color-grey-dark-1

.toggle-main-toolbar__wrapper
  &-lower
    display flex
    flex-grow 1
.toggle-main-toolbar
  z-index 10  
  position absolute
  right 0
  top 1.15rem
  border 0 none
  background-color transparent!important
  +stacked-layout()
    display none
  &__lower
    border 0 none
    flex-grow 1
    background-color transparent!important
    &:hover
      cursor pointer

.sidebar__panel
  padding-top $margin-base
  flex-direction column
  background-color $color-grey-dark-3
  background-image $sidebar-bg-color
  display none
  position absolute
  left $sidebar-width-closed + $grid-gutter
  width $sidebar-width-open
  bottom 0
  top 0
  box-shadow 2px 0 4px -2px rgba(0,0,0,0.7)

  .search-results
    background transparent

.sidebar-panel__search-header
  min-height initial
  margin $margin-base $margin-base 0 0

.sidebar-panel__layers-container
  margin $margin-base * 2 (-1 * $grid-gutter) 0 (-1 * $grid-gutter)

// Sidebar tabs
.tab__button
  height 4.5rem
  width $sidebar-width-closed
  color $color-white
  background-color $color-grey-dark-1
  border-width 0 0 2px 0
  border-color rgba(255,255,255,0.3)
  border-radius 0
  &.first
    border-width 2px 0 2px 0
  &:hover
    color $color-orange-base
  &.active
    background-color $color-grey-dark-3
    border-color rgba(255,255,255,0.3) rgba(255,255,255,0.3) $color-orange-base rgba(255,255,255,0.3)
  &:focus-visible
    outline none
    box-shadow 0px 0px 0px 4px #fff inset
  .glyphicon
    top 0.125rem
    width 60%
    height 60%

//Open sidebar
.sidebar.open
  background-image $open-sidebar-bg-color
  .search-results
    position absolute
    left 0
    top "calc(100% - %s)" % $margin-base
  +column-layout()
    width $sidebar-width-open
    z-index 1

  .only-closed-sidebar-button
    display none

  .toggle-main-toolbar
    right 0.5rem
    top 1.15rem
    background-color $color-grey-dark-3

  .toggle-main-toolbar__lower
    display none

  .sidebar-panel__close-button
    display none

  .sidebar-panel__search-header
    margin $margin-base 0 $margin-base 0


when-sidebar-is-closed()
  +column-layout()
    .sidebar.closed
      {block}

+when-sidebar-is-closed()
  width $sidebar-width-closed
  > *
    width $sidebar-width-closed

+when-sidebar-is-closed()
  .logo__container, .sidebar__panel
    display none

  .toggle-main-toolbar__wrapper
    height 4rem

  .maptool__button
    border-bottom-width 1px
    border-bottom-color rgba(255,255,255,0.3)
    height 4.5rem
    width $sidebar-width-closed
    &.active
      border-bottom-color $color-orange-base
      border-bottom-width 2px
  .maptool-button__title
    display none

  .sidebar__panel.sidebar-panel-open
    display flex

  .sidebar-panel__close-button
    position absolute
    z-index 2
    color $color-white
    top $margin-base
    right $margin-base
    &:focus-visible
      outline 2px solid #fff
