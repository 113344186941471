
/*
  <button type="button" class="button" />
  <button type="submit" class="button" />
  <a class="button" />

  Variations:
    .button--large
    .button--blue
    .button--light
    .button--orange
    .disabled / disabled

    .button--icon
*/

$button
  ubuntu-medium-font()
  display inline-block
  margin $margin-half 0
  background transparent
  border-width 2px
  border-style solid
  border-color transparent
  border-radius $radius-border
  text-align center
  text-decoration none
  white-space nowrap
  cursor pointer
  &:hover
    text-decoration none
  &.disabled,
  &:disabled
    color $color-text-button !important
    background $color-bg-button-disabled !important
    border-color $color-bg-button-disabled !important
    cursor not-allowed
    text-decoration none

.button
  @extend $button
  min-width 9.5rem
  height 3.6rem
  padding 0.25rem 0.75rem

.button__quantity
  @extend $button
  display flex
  min-width 8.5rem
  align-items center
  text-align left
  white-space normal
  color $color-remove-button
  cursor pointer
  padding 0

  &.no-label
    min-width 0rem

  &:focus-visible
    outline none
    border solid 2px $color-blue-base

  .glyphicon
    +stacked-layout()
      .text
        display none
    top 0
    left 0
    display block
    width 2.2rem
    height 2.2rem
    margin-right $margin-base

.button__collapsible
  @extend .button__quantity
  .glyphicon
    margin-right 0.5rem

.button__collapsible--inline
  @extend $button
  color $color-remove-button
  padding 0.5rem 0 0 0
  border 0
  .glyphicon
    margin-top 1px

.sub-heading-button
  margin 0

.button--large
  min-width 14.5rem
  height 4.125rem
  padding 0 1rem

.button--small
  min-width 0
  height auto

.button__long__text
  @extend $button
  text-align left
  white-space normal
  min-width 9.5rem
  padding 0.25rem 0.75rem

a
  &.button
    display inline-flex
    align-items center
    justify-content center
    width fit-content

  &.button--large
    line-height 2.125rem

.button--blue
  text-decoration none !important
  color $color-text-button
  background $color-blue-dark-2
  border-color $color-blue-dark-2
  &:active
    color $color-text-button
    background $color-blue-dark-3
    border-color $color-blue-dark-3
  &:focus-visible
    outline solid $color-white 2px
    outline-offset -6px

.button--light
  color $color-blue-dark-2
  background $color-blue-light-3
  border-color $color-blue-base
  &:active
    color $color-blue-dark-3
    background $color-blue-light-2
    border-color $color-blue-base
  &:focus-visible
    outline solid $color-blue-dark-3
    outline-offset -5px
    border-color $color-blue-dark-3

.button--dark
  background $color-grey-dark-3
  color $color-white
  border-radius 5px
  &:disabled
    background-color lighten($color-grey-dark-2, 20%) !important
  &.dark-selected
    background-color $color-orange-base !important
    color $color-white !important
  &:focus-visible
    outline none
    box-shadow 0 0 0 2px $color-grey-dark-2, 0px 0px 0px 4px $color-orange-base

.button--orange
  color $color-orange-dark-3
  background $color-orange-light-3
  border-color $color-orange-base
  &:active
    color $color-orange-dark-3
    background $color-orange-light-2
    border-color $color-orange-dark-1
  &:focus-visible
    outline solid $color-orange-dark-2
    outline-offset -5px
    border-color $color-orange-dark-2
    &:active
      box-shadow 0 0 0 4px $color-orange-dark-1 inset

.button--icon
  width $width-button-icon
  height $width-button-icon
  min-width 0
  margin 0
  padding 0
  .glyphicon
    width 71%
    height 71%
  &:focus-visible
    outline-offset -4px

.button--link
  color $color-link
  text-decoration underline
  height initial
  padding: 0
  margin: 0
  &:focus-visible
    outline-color $color-blue-base

.button--icon-large
  @extend .button--icon
  width $width-button-icon-large
  height $width-button-icon-large

.button--icon-and-text
  display flex
  align-items center
  justify-content center
  font-size 1.6rem
  .glyphicon
    font-size 2.4rem
    margin-right 0.2em

.button--flexible
  min-width inherit
  color inherit

.button--narrow
  min-width 0

.button--full-width
  width 100%

.button--transition
  transition height 0.2s, opacity 0.1s 0.2s


.button--multiline
  padding 0.5rem 0.75rem
  min-height 3.6rem
  height auto
  white-space normal


/*
Button group containers
*/

// TODO: check that these changes don't break shit
.button-row
  display flex

.button-row--right-align
  > :first-child
    margin-left auto

/*
  Hover-styles
*/
+pointer-states()
  .button--blue
    &:hover
      color $color-text-button
      background $color-blue-base
      border-color $color-blue-base
  .button--light
    &:hover
      color $color-blue-dark-2
      background #f9fcfc
      &:not(:focus)
        border-color $color-blue-light-1
  .button--dark
    &:hover
      color $color-orange-base
      background-color $color-grey-dark-3
  .button--orange
    &:hover
      color $color-black
      background $color-orange-light-2
      &:not(:focus)
        border-color $color-orange-dark-1
