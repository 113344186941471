//Map selector Slider
$layer-eye-spacing = 5.6rem
$ctrl-slider-height = 1.4rem
$ctrl-slider-handle-height = 1.4rem
$ctrl-slider-handle-height-offset = -($ctrl-slider-handle-height) / 2
$ctrl-slider-handle-width = 2.4rem
$ctrl-slider-handle-width-offset = $ctrl-slider-handle-width / 2


//Layers tool
$layer-text-color = $color-white
$layer-disabled-bg-color = transparentify($color-grey-dark-3,0.6)
$layer-disabled-text-color = $color-grey-base
$closed-layer-text-color = $color-blue-base
$layer-visibility-label-color = $color-orange-base

// SLIDER:
$ctrl-slider-handle-color = $color-blue-base
$ctrl-slider-track-color = rgba(255,255,255,0.2)
$ctrl-slider-track-hilight-color = rgba(255,255,255,0.4)
$ctrl-slider-track-width = 21.4rem
$ctrl-slider-inner-width = 26rem
$ctrl-slider-inner-width-desktop = 19rem


.layer-controls__ul
  list-style none
  padding 0
  margin 0
  max-height 50rem
  & > *
    border-top 1px solid rgba(255,255,255,0.2)
  
.layer-controls__li
  border-top 1px solid rgba(255,255,255,0.2)
  .glyphicon
    margin 0 1.4rem

// YES, but rename to button or something
.layer-controls__button
  background-color transparent
  border none
  padding 0 0 1.5rem 0
  width 100%
  cursor pointer
  color $closed-layer-text-color
  display flex
  align-items center
  +column-layout()
    height 5.2rem
    font-size 2.6rem
  +stacked-layout()
    height 5.8rem
    font-size 3.5rem
  &.visible
    color white
    .glyphicon
      color $layer-visibility-label-color
    &:focus-visible
      outline none
      box-shadow 0 0 0 3px $color-orange-base inset
  &:disabled
    color $layer-disabled-text-color
    background-color $layer-disabled-bg-color
    .glyphicon
      color $layer-disabled-text-color
  &:focus-visible
    outline none
    box-shadow 0 0 0 3px $closed-layer-text-color inset
  & > *
    outline none

.layer-controls__name
  font-size 1.8rem

.layer-controls__not-in-range
  font-size 1.2rem
  line-height 0.6rem
  position absolute
  top 0
  color $color-grey-light-2
  white-space nowrap
  pointer-events none

.map-layer-selector__legend-link
  padding-top $margin-base
  padding-left 1rem + 0.3rem
  & > .external-link
    color $color-orange-base

//RC SLIDER Styles

.layer-controls__slider
  position relative
  bottom 2rem
  left 6.5rem
  width calc(100% - 11rem)
  +column-layout()
    left 5.5rem
    width $ctrl-slider-inner-width-desktop

  .rc-slider
    position absolute
    left $ctrl-slider-handle-width-offset
    &:after
      content: ''
      position absolute
      height 1px
      left -($ctrl-slider-handle-width-offset)
      background-color  $ctrl-slider-track-color
      +column-layout()
        width $ctrl-slider-track-width
      +stacked-layout()
        width calc(100% + 1.6rem)
    &.rc-slider-disabled
      .rc-slider-handle
        background-color $color-grey-base

  // this is track highlight color
  .rc-slider-track
    display block
    height 1px
    background $ctrl-slider-track-hilight-color
    left -($ctrl-slider-handle-width-offset) !important

  .rc-slider-handle
    border-radius 3px
    border 0
    box-shadow 0 0 0 1px #333
    background-color $ctrl-slider-handle-color
    width $ctrl-slider-handle-width
    height $ctrl-slider-handle-height
    margin-top $ctrl-slider-handle-height-offset
    z-index 1
    &:focus-visible
      outline none
      box-shadow 0 0 0 3px $color-white


+pointer-states()
  .layer-controls__slider
    :not(.rc-slider-disabled)
      .rc-slider-handle
        &:not(:active):not(:focus):hover
          box-shadow 0 0 7px 0 $color-grey-light-1
