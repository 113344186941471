$user-menu-active-color = $color-grey-light-1
$user-menu-cart-count-bg-color = $color-orange-base
$user-menu-hover-color = $color-blue-light-2
$user-menu-hover-logout-color = $color-blue-light-3
$user-menu-cart-bubble-color = $color-orange-base
$user-menu-button-color = $color-blue-additional-dark-1

$user-menu-height = 5rem
$user-menu-cart-count-size = 1.9rem
$user-menu-dropdown-content-line-height = 4rem

$user-menu-dropdown-content-border-radius = 3px

.user-menu__wrapper
  +column-layout()
    width 0
    padding 0
  &.back
    .user-menu___user-name
      .ellipsis
        max-width 17rem !important

.user-menu
  display flex
  align-items center
  height $user-menu-height
  background-color $color-white
  color $color-link
  border-bottom 1px solid $user-menu-hover-color
  box-shadow 0 0 4px 0 rgba(0,0,0,0.5)
  position relative
  z-index 10000
  +column-layout()
    width $business-panel-width-open
    margin 0
  .glyphicon
    font-size 2.3rem
  +stacked-layout()
    justify-content space-between
  .user-menu--right
    +column-layout()
      margin-left auto !important

  .user-menu__item-block
    border-radius 0
    height 100%
    margin 0 -1px
    display flex
    align-items center
    padding 0 1.1rem
    transition background-color 0.2s linear
    overflow hidden
    max-height $user-menu-height
    &:focus-visible
      blue-focus-visible(-4px)
    +stacked-layout()
      padding 0
    .item-block__content
      .user-menu__back-arrow
        margin-right ($margin-base / 2)
        margin-left ($margin-base / 2)
        font-size 2rem

  .user-menu__cart-button
    position relative
    .glyphicon
      font-size 2.8rem
    .cart-button__count
      color $color-white
      background-color $user-menu-cart-count-bg-color
      position absolute
      right 0rem
      bottom 0.4rem
      width $user-menu-cart-count-size
      height $user-menu-cart-count-size
      line-height $user-menu-cart-count-size
      text-align center
      border-radius 50%

  .user-menu__item-divider
    width 0
    height 60%
    border-left 1px solid $user-menu-hover-color
    flex-shrink 0

  .user-menu__language-selector
    margin-right -2rem
    display flex
    text-transform uppercase
    min-width 9rem
    .language-selector__slash
      color $user-menu-hover-color
      padding 0 0.25rem
    button
      border-radius 0
      padding 0.75rem 0.50rem
      border none
      background none
      color $user-menu-button-color
      cursor pointer
    .active
      color $user-menu-active-color
      pointer-events none
      cursor default

  .mapSite-selector
    min-width 12.5rem

  .language-selector__button
    &:hover
      text-decoration underline
    &:focus-visible
      blue-focus-visible()

  .user-menu__dropdown
    white-space normal
    +stacked-layout()
      padding 0
      margin 0
      min-width 0
    .dropdown__arrow-icon
      font-size $font-size-base

  .user-menu-dropdown__content
    width 25rem
    padding 1rem 1.5rem
    position absolute
    background $color-blue-light-3
    border-radius $user-menu-dropdown-content-border-radius
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.6)
    top $user-menu-height + 0.2rem
    +stacked-layout()
      top $user-menu-height + 0.2rem
      left 1vw
      width 98vw
    right 0rem
    z-index 11

    header
      color $color-heading-base
      font-size 1.5rem
      padding 0 0 0.5rem
      word-break break-word
      h2
        font-family inherit
        font-size 1.5rem
        font-weight bold
        margin 0
        padding 0
        line-height normal

    .logout-button
      display inline-flex
      justify-content center
      align-items center
      margin 0 auto
      padding-right 1rem
      .glyphicon
        font-size 150%
        display inline-block
        margin-bottom 0px
        position relative
        bottom -0.05em

  .user-menu___user-name
    display inline-flex
    justify-content flex-start
    align-items flex-start
    max-height 4rem
    line-height 2rem
    .ellipsis
      display block
      max-width 40rem
      overflow hidden
      text-align right
      text-overflow ellipsis

  .user-menu__cart-bubble
    z-index 100
    opacity 1
    position relative
    color white
    .cart-bubble__content
      right: 1.3rem
      position absolute
      background $user-menu-cart-bubble-color
      border-radius 3px
      top 0rem
      min-width 23rem
      min-height 3rem
      padding 0.5rem
      display flex
      flex-direction row
      flex-wrap nowrap
      justify-content space-between
      align-items stretch
      box-shadow 0px 2px 5px 1px rgba(0,0,0,0.5)
      button
        padding-right 1rem
      .glyphicon
        margin 0
        width 1.5rem
        height 1.5rem

      &::after
        content ''
        width 0
        height 0
        border-style solid;
        border-width 0 10px 10px 10px
        border-color transparent transparent $user-menu-cart-bubble-color transparent
        position absolute
        top -0.9rem
        right 0.9rem

    .cart-bubble__text
      padding 0 0.3rem
      align-self center
      white-space pre-line

    .button
      color inherit

.karttapaikka
  .user-menu
    .user-menu-dropdown__content
      left 0
      right auto

// Hovers
+pointer-states()
  .user-menu
    .user-menu__item-block
      &:not(.no-hover)
        &:hover
          background-color $user-menu-hover-color
          text-decoration none
    .user-menu-dropdown__content
      .dropdown-content__list
        *
          &:hover
            text-decoration none
        .dropdown-content-list__item
          &:hover
            background-color $user-menu-hover-color