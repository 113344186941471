.printmap-page__body-wrapper
  width 100%
  height 100%
  background $color-panel-list-white-bg
  color $color-text-base
  list-style none
  overflow-x auto
  overflow-y auto
  position relative
  margin 0 auto
  padding 1rem

.printmap-page__header
  display flex
  align-items center
  margin-bottom 20px

.printmap-page__logo
  height auto
  width 120px
  margin-right 30px

.printmap-page__title
  font-family 'TitilliumWeb-SemiBold'
  font-size 3rem
  line-height 1.35

.printmap-page__error-message
  font-size 2.8rem
  margin 4rem 0

@media only print
  *
    margin 0 !important
    padding 0 !important

  @page
    size  auto   /* auto is the initial value */
    margin 3mm  /* this affects the margin in the printer settings */

  html
    background-color: #FFFFFF
    margin 12mm 3mm 3mm 3mm  /* this affects the margin on the html before sending to printer */
    height 100%
    overflow hidden

  .screen-only
    display none

  .printmap-page__header
    display flex
    align-items center
    margin-bottom 4mm !important

  .printmap-page__logo
    width 20mm
    margin-right 4mm !important

  .printmap-page__title
    font-size 18pt

  .printmap-page__printed-image
    padding 0
    width 100%

  .disclaimer
    margin-top 2mm !important

  .grid-row
    margin-bottom 1rem !important

  .nls-heading-h3
    margin-bottom 0.25rem !important