//COPIED FROM node_modules/stylus-media-queries

/*======================================================================
    SCREEN SIZE
======================================================================*/
screen(minWidth, maxWidth)
  @media screen and (min-width: minWidth) and (max-width: maxWidth)
    {block}

max-screen(maxWidth)
  @media screen and (max-width: maxWidth)
    {block}

min-screen(minWidth)
  @media screen and (min-width: minWidth)
    {block}

screen-height(minHeight, maxHeight)
  @media screen and (min-height: minHeight) and (max-height: maxHeight)
    {block}

max-screen-height(maxHeight)
  @media screen and (max-height: maxHeight)
    {block}

min-screen-height(minHeight)
  @media screen and (min-height: minHeight)
    {block}


/*
 * Layout mixins
 */

stacked-layout()
  +max-screen($breakpoint-desktop)
    {block}

column-layout()
  +min-screen($breakpoint-desktop + 1)
    {block}

// Mixin to prevent notification overlap with the right column
notification-fit()
  +max-screen(74rem)
    {block}

/*
*
* Business panel desktop state helpers
*/
when-desktop-panel-is-closed()
  .business-panel__wrapper.closed
    +column-layout()
      {block}

when-desktop-panel-is-open()
  .business-panel__wrapper.open
    +column-layout()
      {block}

when-mobile-panel-is-closed()
  .business-panel__wrapper.closed
    +stacked-layout()
      {block}

when-mobile-panel-is-open()
  .business-panel__wrapper.open
    +stacked-layout()
      {block}

when-map-is-fixed()
  .map-fixed
    +column-layout()
      {block}

when-map-is-flex()
  .map-flex
    +column-layout()
      {block}


// Clearfix
/*
  For the container to contain the floated items.
*/

clearfix()
  &:before,
  &:after
    content " "
    display table
  &:after
    clear both

/*
  Focus outline for such custom constructions as react-select
  or that fancy input[type=checkbox] + label.

  In most cases, you definitely should not need this, as browsers do
  lovely work on their own.

  This adds first a semi-sensible outline for all browsers, then overrides
  it for webkit with a native looking outline.
*/

focus-outline()
  outline rgba($color-blue-base, 0.7) auto medium
  outline -webkit-focus-ring-color auto thick

// For html.no-touchevents
pointer-button-states(hoverbg, hovertext, activebg, activetext)
  &:hover
    background-color hoverbg
    color hovertext
  &.active,
  &.active:focus
    background-color activebg
    color activetext

// For html.touchevents
touch-button-states(activebg, activetext)
  &.active
    background-color activebg
    color activetext

// For html.touchevents
tap-hovers(hoverbg, hovertext)
  &.Tappable-active
    background-color hoverbg
    color hovertext

pointer-states()
  @media(hover: hover) and (pointer: fine)
    {block}

touch-states()
  html.touchevents
    {block}


//  Animation helpers
fast-width-animation()
  transition width 0.15s ease-in


fast-height-animation()
  transition height 0.15s ease-in

fast-height-animation()
  transition height 0.15s ease-in

medium-bottom-animation()
  transition bottom 0.3s ease-in

fast-all-animation()
  transition all 0.15s ease-in

medium-all-animation()
  transition all 0.3s ease-in

slow-all-animation()
  transition all 0.7s ease-in

vertically-aligned(position)
  position position
  top 50%
  transform translateY(-50%)

list-reset()
  list-style none
  padding 0
  margin 0

blue-focus-visible(offset = 0px)
  outline 3px solid $color-blue-base
  outline-offset offset
