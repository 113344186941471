html,
body
  height 100%

html
  font-size 62.5%; /* Now 10px = 1rem! */

body
  ubuntu-regular-font()
  background $color-bg-base
  color $color-text-base
  font-size $font-size-base
  line-height $line-height-base
  touch-action manipulation

h1
  @extend $h1

h2
  @extend $h2
  &.with-divider
    border-bottom 2px solid $color-blue-light-2
    padding-bottom 1.5rem

h3
  @extend $h3
  &.with-divider
    border-bottom 2px solid $color-blue-light-2
    padding-bottom 0.5rem

h4
  @extend $h4

h5
  @extend $h5

h6
  @extend $h6

p
  margin $margin-half 0
  a
    text-decoration underline
  .external-link
    text-decoration underline
  &.with-divider
    border-bottom 2px solid $color-blue-light-2
    padding-bottom 1.5rem
    margin-bottom 2rem

a
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    color $color-link
    text-decoration none
    &:active,
    &:hover
      text-decoration underline

a.panel-list-item__link
  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    &:active,
    &:hover
      text-decoration none

// Set outline (box-shadow) except for button-links
a:not(.button):focus-visible
  outline none
  border-radius 1px
  box-shadow 0px 0px 0px 3px $color-blue-base

// Do not do double focus in panel-list
.panel-list__item > a.panel-list-item__link:focus-visible
  border-radius 1px
  box-shadow none
  .panel-list-item__divider
    visibility hidden
  .with-divider
    border-color $color-blue-base

// Make box-shadow work in active items
div.panel-list__wrapper
  a.panel-list-item__link:focus-visible
    outline none
    border-radius 1px
    box-shadow 0px 0px 0px 3px $color-blue-base

// Make box-shadow work in activity log
div.panel-list__wrapper.activity-log
  a.panel-list-item__link:focus-visible
    outline none
    border-radius 1px
    box-shadow inset 0px 0px 0px 3px $color-blue-base
  
/*
  iOS "no text when typing" bugfix
 */
input
  user-select auto !important

label
  cursor pointer