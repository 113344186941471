.nls-infobox
  background $color-white
  position relative
  margin-top 2.1rem
  
.nls-infobox__icon
  color $color-blue-light-1
  font-size 3rem
  position absolute
  right 2rem
  top -1.8rem

.nls-infobox__content
  margin 1.75rem

.nls-infobox.info
  border 2px solid $color-blue-light-1

.nls-infobox.warning
  border 2px solid $color-orange-base

.nls-infobox.error
  border 2px solid $color-red-error
