.file-download-page
  background-color $color-white

  &.app
    overflow visible

  .layout-container
    max-width 960px
    margin 0 auto
    padding 0 2rem

  .error-page
    padding-bottom 35rem

  .file-list
    list-style-type none
    padding-left 0
    li
      display grid
      row-gap: 1em;
      +column-layout()
        grid-template-rows 1fr auto auto auto
        grid-template-columns 1fr 80px auto auto
        grid-template-areas 'link size meta-toggle meta-link' 'meta meta meta meta' 'info info info info'
      +stacked-layout()
        grid-template-areas 'link link link' 'size size size' 'meta-toggle meta-link meta-link' 'meta meta meta' 'info info info'
      align-items center
      padding 1rem 0
      border-top 1px solid $color-blue-light-2
      &:first-of-type
        border-top 2px solid $color-blue-light-2
      &:last-of-type
        border-bottom 2px solid $color-blue-light-2

  .file-list
    @media print
      li
        display flex
      .file-list__link, .file-list__size
        flex-grow 1

  .file-list__link
    grid-area link
    position relative
    padding-left 2.5rem
    a
      position relative
      margin-left -2.5rem
      padding-left 2.5rem
    .glyphicon
      position absolute
      left 0

  .file-list__meta-link
    grid-area meta-link
    position relative
    padding-left 5rem
    a
      position relative
      margin-left -2.5rem
      padding-left 2.5rem
    .glyphicon
      position absolute
      left 0

  .file-list__size
    grid-area size

  .file-list__link-error
    grid-area link
    position relative
    padding-left 2.5rem
    color $color-red-error
    
  // Collapsible's toggle button container
  .file-list
    li
      .grid-row
        grid-area meta-toggle
        justify-self start

  // Collapsible's content container
  .file-list
    li
      .collapsible__content
        grid-area meta

  .file-list__info
    grid-area info
    padding-left 2.5rem

  .button__collapsible
    margin 0 0 0 0.3rem

  .button--download
    .glyphicon
      margin-right 1rem
      font-size 1.6rem

  .meta-list__item
    display flex

  .meta-list__key
    width 22rem
    flex-shrink 0

  .meta-list__value
    margin-left 2rem
    word-break break-all

  .footer
    border-top solid $color-grey-light-3 1px

  .footer__link-box
    max-width 960px
    margin 0 auto
    border-top 0

  .footer__slogan
    max-width 960px
    margin 0 auto

.file-download-page__licenced__row
  border-bottom 2px solid $color-blue-base
  padding-top 2rem
  padding-bottom 2rem
  padding-left 0.5rem

  div
    flex 1

.file-download-page__header
  align-items center

.file-download-page__user-menu
  font-size 1.8rem
  color $color-blue-additional-dark-1

.file-download-page__user-menu__dropdown
  position absolute
  width 25rem
  background $color-white
  right 1rem
  padding 2rem
  border-radius $user-menu-dropdown-content-border-radius
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.6)
  font-size 1.5rem

  .logout-button
    display inline-flex
    justify-content center
    align-items center

.file-download-page__user-menu__dropdown.close
  display none

.file-download-page__licenced__loading
  align-items center

.product-file-list
  &:last-of-type
    padding-bottom 2rem
    border-bottom 2px solid $color-blue-light-2
  .product-file-item
    margin-left 2.5rem

.map-details-list
  list-style none
  padding-left 0
  margin 0.5rem 0
  li
    padding 0.25rem 0
    &:first-of-type
      padding-top 1rem


