.notfound-text
  titillium-light-font()
  font-size 20rem
  line-height 16.5rem
  color $color-red-base
  .notfound-fallen
    transform rotate(115deg)
    margin-left 2rem
    margin-top 4rem
    position absolute
  .error-fallen
    transform rotate(-27deg)
    margin-left -2rem
    position absolute

