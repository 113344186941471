$notification-border-size = 2px

.sidebar-open.business-panel-open.desktop
  .app-notifications
    +notification-fit()
      margin-left (-($sidebar-width-open))

.app-notifications
  box-shadow 0 6px 11px -3px rgba(0, 0, 0, 0.2)
  top 0
  left 0
  right 0
  z-index 300
  +stacked-layout()
    position fixed
  +column-layout()
    position absolute
  &.open
    display block
  &.closed
    display none

@keyframes highlight-count
  0%
    background: white
  100%
    background: $color-grey-dark-2

@keyframes highlight-count
  0%
    background: white
  100%
    background: alpha($color-grey-dark-2, 0.9)

@keyframes highlight-info
  0%
    background: white
  100%
    background: $color-blue-base

@keyframes highlight-warning
  0%
    background: white
  100%
    background: $color-orange-base

@keyframes highlight-error
  0%
    background: white
  100%
    background: $color-red-additional

.notification__count
  display none
  background-color $color-grey-dark-2
  color $color-white
  padding 0.2rem 1rem
  font-size $font-size-base
  animation highlight-count 1s

.rerun .notification__count
  animation highlight-count-rerun 1s

.more .notification__count
  display block

.notification__item
  display flex
  align-items center
  padding 0.5rem
  color $color-white
  font-size 1.5rem
  &.info
    background-color $color-blue-base
    animation: highlight-info 1s;
  &.warning
    background-color $color-orange-light-3
    color $color-orange-dark-3
    animation: highlight-warning 1s;
    &.notification___link
      color $color-blue-additional
  &.error
    background-color $color-red-additional
    animation: highlight-error 1s;
  &.bulletin
    padding-bottom 0

  .notification__link
    color inherit
    margin-left 0.2rem

  .notification__type-icon, .notification__close-icon
    align-self flex-start
    font-size 2rem
  .notification__type-icon
    margin-top 0.25rem

  .notification-message
    padding 0.5rem 0.625rem

  .notification-bulletin
    padding 0.5rem 0.625rem 0 0.625rem

  .notification-item--right
    margin-left auto

  span.bold
    display inline-block