fontPath ?= '../../fonts/'

/* Ubuntu Regular */
@font-face
  font-family 'Ubuntu'
  src url(fontPath + 'ubuntu/ubuntu-regular-webfont.woff2') format('woff2')
  font-weight normal
  font-style normal

/* Ubuntu Regular Italic */
@font-face
  font-family 'Ubuntu'
  src url(fontPath + 'ubuntu/ubuntu-regular-italic-webfont.woff2') format('woff2')
  font-weight normal
  font-style italic

/* Ubuntu Medium */
@font-face
  font-family 'Ubuntu'
  src url(fontPath + 'ubuntu/ubuntu-medium-webfont.woff2') format('woff2')
  font-weight 500
  font-style normal

/* Ubuntu Medium Italic */
@font-face
  font-family 'Ubuntu'
  src url(fontPath + 'ubuntu/ubuntu-medium-italic-webfont.woff2') format('woff2')
  font-weight 500
  font-style italic

/* Ubuntu Bold */
@font-face
  font-family 'Ubuntu'
  src url(fontPath + 'ubuntu/ubuntu-bold-webfont.woff2') format('woff2')
  font-weight bold
  font-style normal

/* Ubuntu Bold Italic */
@font-face
  font-family 'Ubuntu'
  src url(fontPath + 'ubuntu/ubuntu-bold-italic-webfont.woff2') format('woff2')
  font-weight bold
  font-style italic



