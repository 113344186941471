$uploader-dropzone-height = 7rem
$uploader-dropzone-padding-vertical = 2rem
$uploader-dropzone-padding-horizontal = 1rem
$uploader-dropzone-line-height = $uploader-dropzone-height - $uploader-dropzone-padding-vertical * 2

$uploader-progressbar-border-radius = 3px

/*
 * Gradient helpers
 */

@keyframes shimmer
  0%
    opacity: 1
  50%
    opacity: 0.5
  100%
    opacity: 1

stripe-loading()
  background-color $color-orange-base
  background repeating-linear-gradient(-45deg,
    $color-orange-base,
    $color-orange-base 10px,
    $color-orange-light-2 10px,
    $color-orange-light-2 20px
  )

stripe-done()
  background $color-orange-light-2
  background linear-gradient(
    $color-orange-light-1,
    $color-orange-base 10%
  )

bg-done()
	background $color-uploader-done-bg

bg-error()
  background lighten($color-uploader-error-bg,20%)

/*
 * DropZone
 */

.uploader__dropzone
  position relative
  z-index 1
  height $uploader-dropzone-height
  width 100%
  cursor pointer
  border 1px dashed $color-uploader-border
  border-radius 3px
  background white
  text-align center
  line-height $uploader-dropzone-line-height
  padding $uploader-dropzone-padding-vertical $uploader-dropzone-padding-horizontal
  margin $margin-base 0
  &.file-input__disabled
    color $color-uploader-border
    &:hover
      background white
      cursor default
    .button--link
      color $color-uploader-border
      cursor default
      text-decoration none
  &:hover
    background $color-uploader-border-hover
  &:focus
    outline none
    border-color $color-blue-base
    border-style solid
    box-shadow 0 0 0 1px $color-blue-base inset, 0 0 0 1px $color-blue-base
  .button--link
    min-width 0

/*
 * Progress bar
 */

.uploader-progress__wrapper
  display flex
  flex-direction column
  +min-screen($breakpoint-desktop + 1)
    width "calc(%s - 4rem)" % ($business-panel-width-open)
  &:not(:last-child)
    margin-bottom $margin-base

.uploader__progressbar
    /* Reset the default appearance */
  appearance none
  border-radius $uploader-progressbar-border-radius
  width 100%

   /* Firefox */
  border none
  background #EEE
  box-shadow 0 2px 3px rgba(0,0,0,0.2) inset

/* Determinate */
.uploader__progressbar[value]
  /* Reset the default appearance */
  appearance none
  border-radius $uploader-progressbar-border-radius
  width 100%
/* Indeterminate */
.uploader__progressbar:not([value])
  /* Reset the default appearance */
  appearance none
  border-radius $uploader-progressbar-border-radius
  width 100%

.uploader__progressbar
  &::-webkit-progress-bar
    background-color #eee
    border-radius $uploader-progressbar-border-radius
    box-shadow 0 2px 5px rgba(0, 0, 0, 0.25) inset

  &::-moz-progress-bar
    background-color #eee
    border-radius $uploader-progressbar-border-radius
    box-shadow 0 2px 5px rgba(0, 0, 0, 0.25) inset
  &::progress-bar
    background-color #eee
    border-radius $uploader-progressbar-border-radius
    box-shadow 0 2px 5px rgba(0, 0, 0, 0.25) inset

/*
 * Progressing
 */

.uploader__progress--ongoing::-moz-progress-bar
  stripe-loading()
  border-radius $uploader-progressbar-border-radius
.uploader__progress--ongoing::-webkit-progress-value
  stripe-loading()
  border-radius $uploader-progressbar-border-radius
.uploader__progress--ongoing::progress-value
  stripe-loading()
  border-radius $uploader-progressbar-border-radius

/*
 * Progress done
 */
.uploader__progress--done
  animation shimmer 2s ease infinite
.uploader__progress--done::-moz-progress-bar
  bg-done()
  border-radius $uploader-progressbar-border-radius
.uploader__progress--done::-webkit-progress-value
  bg-done()
  border-radius $uploader-progressbar-border-radius
.uploader__progress--done::progress-value
  bg-done()
  border-radius $uploader-progressbar-border-radius

/*
 * Progress error
 */

.uploader__progress--error::-moz-progress-bar
  bg-error()
  border-radius $uploader-progressbar-border-radius
.uploader__progress--error::-webkit-progress-value
  bg-error()
  border-radius $uploader-progressbar-border-radius
.uploader__progress--error::progress-value
  bg-error()
  border-radius $uploader-progressbar-border-radius

.file-input__error
  color $color-input-error-text
  background-color $color-input-error-background
  border-color $color-input-error-border


.uploader__progress-enter
  opacity 0
  margin -5rem 0 1.4rem 0
.uploader__progress-enter-active 
  opacity 1
  margin 0
  transition opacity 500ms, margin 500ms
.uploader__progress-exit 
  opacity 1
  margin 0
.uploader__progress-exit-active
  opacity 0
  margin -5rem 0 1.4rem 0
  transition opacity 1500ms, margin 500ms ease 1000ms

.uploaded-list-enter
  opacity 0
  margin-top -3.4rem
.uploaded-list-enter-active
  opacity 1
  margin-top 0
  transition opacity 500ms, margin-top 500ms
.uploaded-list-exit
  opacity 1
  margin-top 0
.uploaded-list-exit-active
  opacity 0
  margin-top -3.4rem
  transition opacity 500ms, margin-top 500ms

.uploaded-list__wrapper
  flex-grow 2
  justify-content center

.uploaded-list-file-name
  +min-screen($breakpoint-desktop + 1)
    width "calc(%s - 15rem)" % ($business-panel-width-open)
  +max-screen($breakpoint-desktop)
    width calc(100% - 10rem)
