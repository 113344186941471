$footer-link-box-border-color = $color-grey-light-3
$footer-link-hover-color = $color-blue-base

$footer-content-padding-sides = 1.875rem

.footer
  color $color-text-base
  background-color $color-footer-bg

.footer__heading
  titillium-light-font()
  font-size 2.4rem
  color $color-text-base
  margin-bottom $margin-base

.footer__link-box
  border-top solid $footer-link-box-border-color 1px
  border-bottom solid $footer-link-box-border-color 2px
  line-height 2.14
  padding-top 1rem
  padding-bottom 2rem
  padding-left $footer-content-padding-sides
  &.single-column
    padding-left 3.5rem

.footer__link-list
  list-reset()
  li
    width 90%
  .link-list__item
    color $color-text-base
    &:hover
      color $footer-link-hover-color
      
.footer__logo
  background-repeat no-repeat
  background-position center
  background-size contain
  height 5rem
  width 9rem

.footer__logo--fi
  background-image url($img-mml-logo-fi-dark)

.footer__logo--sv
  background-image url($img-mml-logo-sv-dark)

.footer__logo--en
  background-image url($img-mml-logo-en-dark)

.footer__slogan
  display flex
  padding 2rem 1.25rem 3rem 1.25rem
  align-items center
  &.single-column
    padding-left 3rem

.slogan__block
  margin-left 0rem
  text-align center
  flex-grow 1
  margin-right 10rem