
/*
  What are placeholders in Stylus?
  http://stylus-lang.com/docs/extend.html#extending-placeholder-selectors

  The following placeholder definitions for headings allow
  defining both <h1> and .h1 in this ITCSS architecture
  without extending things messily around.
*/

$h1
  titillium-semibold-font()
  margin $margin-base 0 $margin-half 0
  color $color-heading-base
  font-size $font-size-h1
  line-height $line-height-h1

$h2
  titillium-semibold-font()
  margin $margin-base 0 $margin-half 0
  color $color-heading-base
  font-size $font-size-h2
  line-height $line-height-h2

$h3
  titillium-semibold-font()
  margin $margin-base 0 $margin-half 0
  color $color-heading-base
  font-size $font-size-h3
  line-height $line-height-h3

$h4
  titillium-semibold-font()
  margin $margin-base 0 $margin-half 0
  color $color-heading-base
  font-size $font-size-h4
  line-height $line-height-h4

$h5
  ubuntu-medium-font()
  margin $margin-base 0 $margin-half 0
  color $color-heading-dark
  font-size $font-size-h5
  line-height $line-height-h5

$h6
  ubuntu-medium-font()
  margin $margin-base 0 $margin-half 0
  color $color-heading-dark
  font-size $font-size-h6
  line-height $line-height-h6
