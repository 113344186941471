.mobile-tools
  background-image linear-gradient(200deg, $color-grey-dark-1 0%, $color-grey-dark-3 150%)
  box-shadow 0px 0px 5px 0px rgba(0,0,0,0.5)
  height 100%
  .mobile-tools__bar
    display flex
    align-items center
    height 100%
    .button--dark
      border 1px solid #aaa
      &.dark-selected
        border 1px solid $color-orange-base
    .right
      margin-left auto
      padding-left 0
    .button
      width 4.2rem
      height 4.2rem
      +max-screen(356px)
        width 3.2rem
        height 3.2rem
    .mobile-tools__button-row
      .button
        .glyphicon.fa-etaisyys, .glyphicon.fa-koordinaatit
          path
            transform translateX(-6%)
        .glyphicon.fa-gps-sijainti
          path
            transform translateY(-3%)
        .glyphicon.fa-hampurilainen
          path
            transform translate(-6%, 4%)
        .glyphicon.fa-plus-pallo, .glyphicon.fa-miinus
          path
            transform translate(1%, -3%)
        &:not(:last-of-type)
          margin-right $margin-base
    .mobile-tools-bottom__coordinates
      color $color-white

.mobile-layers
  background alpha($color-grey-dark-3, 0.95)

.mobile-search
  padding-top 0.5rem
  padding-bottom 0.2rem
  border-bottom-left-radius 0
  border-bottom-right-radius 0
  background $color-grey-dark-2
  .main-toolbar-container
    position relative

.mobile-business-panel
  bottom 0

.mobile-map-only
  height 0

.mobile-feature-toggle-btn
  svg
    position relative
    left -2px