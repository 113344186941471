
/*
  <textarea />
*/

.textarea
  display block
  width 100%
  min-height 8.8rem
  margin 0.25rem 0 $margin-half 0
  padding 0.5rem 0.75rem
  color $color-grey-dark-1
  background $color-white
  border 1px solid $color-grey-light-1
  border-radius $radius-border
  &:focus
    outline none
    box-shadow 0 0 0 0.3rem $color-blue-base

.textarea--error
  color $color-input-error-text
  background-color $color-input-error-background
  border-color $color-input-error-border

.textarea-calculator
  color $color-grey-base

.textarea-screen-reader-calculator
  position absolute
  width 1px
  height 1px
  margin -1px
  padding 0
  overflow hidden
  clip rect(0, 0, 0, 0)
  border 0
