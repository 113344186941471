.map-feature-toggle
  color $color-white

.mobile-features-toggle
  background alpha($color-grey-dark-2, 0.9)

  .map-feature-toggle__no-features
    padding-top 1rem

.map-feature-toggle__ul
  padding 0
  margin-top 0
  margin-bottom 2rem

.map-feature-toggle__li
  list-style-type none
  padding-bottom 1rem
  border-top 1px solid rgba(255,255,255,0.2)
  padding-top 0.5rem
  padding-left 0
  display flex

.map-feature-toggle__li--disabled
  color $layer-disabled-text-color
  background-color $layer-disabled-bg-color

  button
    color $color-grey-base

.map-feature-toggle__button
  color $color-orange-base
  cursor pointer
  background none
  border none
  font-size 2.6rem
  padding 0
  &:focus-visible
    outline solid $color-blue-base 4px
    outline-offset 4px

.map-feature-toggle__remove-all-button
  color $color-text-button
  background $color-blue-base-darker
  border-color $color-blue-base
  border-radius $radius-border
  margin-left 1rem
  &:hover
    background $color-blue-light-1
  &:focus-visible
    outline: solid #fff 2px;
    outline-offset: -6px;

.map-feature-toggle-sidebar-button
  svg
    position relative
    left -2px

.map-feature-toggle__remove-btn
  margin-left auto
  margin-right 2rem

.map-feature-toggle__title
  font-size 1.8rem
  padding-top 0.5rem

.map-feature-toggle__title-without-remove
  font-size 1.8rem
  margin-left 5.4rem
  padding-top 0.5rem

.map-feature-toggle__visibility-btn
  margin 0 1.4rem

.map-feature-toggle__visibility-btn--hidden
  color $color-blue-base

.map-feature-toggle__no-features
  margin-left 0.3rem
