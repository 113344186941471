.shopping-cart__main-row
  background $color-panel-blue-bg
  padding 1rem
  display flex

.shopping-cart__total-row
  @extend .shopping-cart__main-row
  .grid-block
    line-height 17px

.shopping-cart__item-row
  padding 1rem
  .removable-data
    flex-direction column
    margin-top $margin-half
  .description-container
    flex-direction column
    align-items flex-start
    .description
      white-space pre-line
  .id-container, .price-container
    width 100%

.shopping-cart__price-text
  color $color-black

.shopping-cart__total-price-text
  color $color-orange-dark-1
  font-size 1.34em

.shopping-cart__invalid-price-text
  text-decoration line-through
