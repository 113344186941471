.sharemarker-instruction-list
  padding-left 1rem * 2
  list-style-type decimal

.sharemarker-instruction-list__item
  padding 0 0 1rem * 2 0.5rem
  font-size 1.8rem
  font-weight 300

.sharemarker-instruction-list__item--with-button
  padding 0 0 1rem 0.5rem