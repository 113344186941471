@-webkit-keyframes fixed-rotation {
  from{-webkit-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);}
  to{-webkit-transform: rotate(360deg);-ms-transform: rotate(360deg);transform: rotate(360deg);}
}

@keyframes fixed-rotation {
  from{-webkit-transform: rotate(0deg);-ms-transform: rotate(0deg);transform: rotate(0deg);}
  to{-webkit-transform: rotate(360deg);-ms-transform: rotate(360deg);transform: rotate(360deg);}
}

.spinner__block
  overflow visible
  display flex
  justify-content space-around
  svg
    animation fixed-rotation 0.75s steps(12,end) infinite !important

.full-panel-loader
  width $business-panel-width-open
  @media (max-width 990px)
    width 100%