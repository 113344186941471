fieldset
  margin 0
  padding 0
  border none

legend
  display block
  overflow auto

/* 
 Safari fix for missing legend styling
*/
@media not all and (min-resolution:.001dpcm)
  @supports (-webkit-appearance:none) and (stroke-color:transparent)
    legend
      margin-bottom 2rem !important

    .form-fieldset
      padding-top 1.5rem !important
