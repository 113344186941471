/*
  Prefix (select) is defined as react-select prop.
  Other classnames are automatically generated by react-select.
  These are just overrides to default styles.
*/
$select-lines-color = $color-grey-light-1
$select-lines-color-hover = $color-grey-base
$select-text-color = $color-grey-dark-1

$select-error-border-hover = darken($color-input-error-border, 20%)
$select-error-text-hover = darken($color-input-error-text, 20%)

$select-disabled-lines = $color-grey-base
$select-disabled-background = $color-grey-light-5

.select
.select__menu-portal
  .select__control
    border-color $select-lines-color
    cursor pointer
    .select__indicator-separator
      margin 0
      background-color $select-lines-color
    .select__dropdown-indicator
      color $select-lines-color
    .select__single-value
      color $select-text-color
  .select__control--is-focused
    border-color $color-blue-base
    box-shadow 0 0 0 1px $color-blue-base
  .select__menu
  .select__menu-portal
    z-index 2
    color $select-text-color
    box-shadow 0 1px 2px 0 rgba(0,0,0,0.4)
    margin 0.3rem 0
    .select__menu-list
      padding $margin-base 0
      .select__option
        cursor pointer
      .select__option--is-selected
        color inherit
        background-color $color-blue-light-2
      .select__option--is-focused
        background-color $color-blue-light-3
      .select__option--is-selected.select__option--is-focused
        background-color $color-blue-light-1


// Error styling
.select.select--error
  .select__control
    border-color $color-input-error-border
    background-color $color-input-error-background
    .select__placeholder, .select__single-value, .select__dropdown-indicator
      color $color-input-error-text
    .select__indicator-separator
      background-color $color-input-error-border
  .select__control--is-focused
    border-color $color-input-error-text
    box-shadow 0 0 0 1px $color-input-error-text


// Hover styling
.select
  .select__control
    &:hover
      border-color $select-lines-color-hover
      .select__indicator-separator
        background-color $select-lines-color-hover
    .select__dropdown-indicator
      &:hover
        color $select-lines-color-hover
  .select__control--is-focused
    &:hover
      border-color $color-blue-dark-1
  // Error hovers
  &.select--error
    .select__control
      &:hover
        border-color $select-error-border-hover
        .select__indicator-separator
          background-color $select-error-border-hover
      .select__dropdown-indicator
        &:hover
          color $select-error-text-hover
      .select__control--is-focused
        &:hover
          border-color $select-error-text-hover


// Disabled styling
.select.select--is-disabled
  .select__control
    border-color $select-disabled-lines
    background-color $select-disabled-background
  .select__single-value
    color $select-disabled-lines
  .select__indicator-separator
    background-color $select-disabled-lines
  .select__dropdown-indicator
    color $select-disabled-lines

