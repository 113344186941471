$ruler-blue = $color-blue-light-2
$ruler-dark-blue = $color-blue-light-1
$ruler-gray = $color-grey-light-2

.ui-ruler--blue
  background $ruler-blue

.ui-ruler--dark-blue
  background $ruler-dark-blue

.ui-ruler--gray
  background $ruler-gray

.ui-ruler--small
  height 1px

.ui-ruler--large
  height 2px
