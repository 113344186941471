.external-link
  text-decoration none
  .glyphicon
    display inline-block
    margin-bottom 0px
    position relative
    bottom 0.11em
    font-size 150%
    line-height inherit
    vertical-align middle
  .glyphicon-download
    margin-right 1rem

.authentication-link
  display flex !important
  .glyphicon
      font-size 150%
      display inline-block
      margin-bottom 0px
      position relative
      bottom -0.05em

.continue-browsing
  .glyphicon
    margin-left 5px
    color #0054ad

.label--checkbox
  a
    text-decoration underline

.transferTaxInfo
  text-decoration underline

.visitedLink
  color $color-purple

.activity-decision-link
  display inline-flex
  align-items center