
/*
  <input type="text" />
  <input type="email" />
  <input type="tel" />
*/

.input-text
  display block
  width 100%
  height $height-input
  margin 0.25rem 0 $margin-half 0
  padding 0 0.75rem
  color $color-grey-dark-1
  background $color-white
  border 1px solid $color-grey-light-1
  border-radius $radius-border
  &:focus
    outline none
    box-shadow 0 0 0 0.3rem $color-blue-base
  &:disabled
    -webkit-text-fill-color $color-grey-dark-1
    background-color $color-grey-light-5
    border-color $color-grey-light-2
    /* Correct opacity for ios devices */
    opacity 1 !important

.input-text--one-third
  width calc(100% / 3)

.input-text--small
  display inline-block
  width 9rem
  margin 0
  text-align center

.input-text__portion-input
  display inline-block
  margin 0.25rem 0
  input[type=number]
    appearance textfield
    margin 0
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button
      -webkit-appearance none
      margin 0

.input-text__portion-slash
  display inline-block
  color $color-grey-dark-1
  height $height-input
  padding 0 1rem
  line-height: $height-input
  &.error
    color $color-red-dark-2 !important
    background-color $color-red-light-1 !important

.input-text--error
  color $color-input-error-text !important
  background-color $color-input-error-background !important
  border-color $color-input-error-border !important

.currency-and-areas-input
  input
    max-width 18rem

.feedback-marker-input
  padding-left 3rem
  padding-top 1rem
  padding-bottom 1rem
  height 3rem
  width 100%

  background-image url($img-feedback-marker-gray)
  background-position 0.2rem center
  background-repeat no-repeat
  background-size 2rem
  background-color $input-bg-color
  font-weight bold

  border  1px solid $color-grey-light-3
  color $color-grey-dark-3

.feedback-marker-input-filled
  background-image url($img-feedback-marker)