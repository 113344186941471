
/*
  Utility classes.

  These are provided with !important on purpose
  as to correctly override any complex selectors
  there may exist.
*/
.nowrap
  white-space nowrap

.block-centered
  margin 0 auto

.bold
  font-weight bold !important

.normal
  font-weight normal !important

.cursor-pointer
  &:hover
    cursor pointer

.desktop-hide
  +column-layout()
    display none !important

.flex-container
  display flex

/*
 * Flexbox tachyons:
 *
 * Flex item classes
 */

.flex-initial
  flex initial

.flex-1
  flex 1

.flex-none
  flex none

.flex-auto
  flex auto

.flex-column
  display flex
  flex-direction column

.float-left
  float left !important

.float-right
  float right !important

.relative
  position relative

.absolute
  position absolute

.top-right
  top 0
  right 0

.top-left
  top 0
  left 0

.bottom-right
  bottom 0
  right 0

.bottom-left
  bottom 0
  left 0

.full-height
  height 100% !important

.hide, .hidden
  display none !important

.invisible
  visibility hidden

.indent-block-1
  margin-left $margin-half !important

.indent-block-2
  margin-left $margin-half * 2 !important

.indent-block-3
  margin-left $margin-half * 3 !important

  +stacked-layout()
    margin 0 $margin-half * 3 !important

.indent-block-4
  margin-left $margin-half * 4 !important

.inline-block
  display inline-block !important

.uppercase
  text-transform uppercase !important

.margin-r-0
  margin-right 0 !important

.margin-r-0-5
  margin-right $margin-half !important

.margin-r-1
  margin-right $margin-base !important

.margin-r-2
  margin-right $margin-base * 2 !important

.margin-r-3
  margin-right $margin-base * 3 !important

.margin-r-4
  margin-right $margin-base * 4 !important

.margin-r-auto
  margin-right auto !important

.margin-b-0
  margin-bottom 0 !important

.margin-b-0-5
  margin-bottom $margin-half !important

.margin-b-1
  margin-bottom $margin-base !important

.margin-b-2
  margin-bottom $margin-base * 2 !important

.margin-b-3
  margin-bottom $margin-base * 3 !important

.margin-b-4
  margin-bottom $margin-base * 4 !important

.margin-b-auto
  margin-bottom auto !important

.margin-t-0
  margin-top 0 !important

.margin-t-0-5
  margin-top $margin-half !important

.margin-t-1
  margin-top $margin-base !important

.margin-t-2
  margin-top $margin-base * 2 !important

.margin-t-3
  margin-top $margin-base * 3 !important

.margin-t-4
  margin-top $margin-base * 4 !important

.margin-t-auto
  margin-top auto !important

.margin-l-grid-gutter
  margin-left $grid-gutter !important

.margin-l-1
  margin-left $margin-base !important

.margin-l-2
  margin-left $margin-base * 2 !important

.margin-l-3
  margin-left $margin-base * 3 !important

.margin-l-4
  margin-left $margin-base * 4 !important

.margin-l-auto
  margin-left auto !important

.margin-b-em-0-5
  margin-bottom 0.5em !important

.mobile-hide
  +stacked-layout()
    display none !important

.notice-1
  color $color-text-notice-1 !important
  font-weight 500 !important

.notice-2
  color $color-text-notice-2 !important
  font-weight 500 !important

.relative
  position relative

.cursor-pointer
  &:hover
    cursor pointer

.text-center
  text-align center

.text-left
  text-align left

.word-break-all
  word-break break-all

.word-break-word
  word-break break-word

.space-between
  justify-content space-between

.price-text
  @extend $h1
  color inherit

/*
* Text overflow truncation
*/
.truncated-text-ellipsis
  text-overflow ellipsis
  white-space nowrap
  overflow hidden

.summary-file-name
  +min-screen($breakpoint-desktop + 1)
    width calc(100% - 4rem)

/*
* For screen readers only
*/
.visually-hidden
  clip rect(0 0 0 0)
  clip-path inset(50%)
  height 1px
  overflow hidden
  position absolute
  white-space nowrap
  width 1px
