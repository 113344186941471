
/*
  Error message.
*/

.validation-message
  margin-top 0.5rem
  padding-left 0
  align-items: center

.validation-message--error
  color $color-red-error
  font-size 1.6rem

.validation--required
  color $color-red-base
  margin-left $margin-half
