$search-result-border-color = $color-grey-dark-1
$search-results-bg-color = transparentify($color-grey-dark-1, 0.9)
$search-results-mobile-bg-color = transparentify($color-grey-dark-2, 0.9)
$search-result-text-color = $color-white

search-result__border()
  border-bottom 1px solid $color-grey-base

search-result-block__border()
  border-top 1px solid $color-grey-base

.search-results
  display flex
  flex-direction column
  justify-content center
  line-height 2rem
  box-shadow 0 5px 5px -5px rgba(0,0,0,.8)
  width 100%
  z-index 30
  +stacked-layout()
    position absolute
    top 39px
    max-height 40rem
    overflow hidden
    background-color $search-results-mobile-bg-color
    border-radius 0
  +column-layout()
    background-color $search-results-bg-color

.search-result-list
  padding 0
  margin 0
  list-style none
  overflow hidden
  position relative

.search-result-list-group
  margin 0 1.5*$margin-base
  list-style none
  padding 0
  .search-result__item:first-of-type
    border-top 0
    .search-result__show-on-map-button
      padding-top 0
  .search-result__item:last-of-type
    border-bottom 0

.search-result__item
  color $search-result-text-color
  min-height 6rem
  font-size $font-size-h4
  position relative
  +stacked-layout()
    border-bottom 1px solid lighten($search-result-border-color, 10%)
  +column-layout()
    border-bottom 1px solid $search-result-border-color

.search-result__show-on-map-button
  height 100%
  width 100%
  min-height 6rem
  margin 0
  padding $margin-base
  text-align left
  color $search-result-text-color
  line-height inherit
  font-weight normal
  white-space initial
  &:focus-visible
    outline none
    box-shadow 0 0 0 3px white inset
  +stacked-layout()
    padding 1.5*$margin-base

.search-result-item__expand-toggle
  color $color-white
  position absolute
  right $margin-base
  top $margin-base
  &:focus
    outline none
    box-shadow 0 0 0 3px $color-blue-base inset
  &:before, &:after
    position absolute
    content ''
    background-color $color-white
    border-radius 1px
  &:before
    left 20%
    top 42%
    height 16%
    width 60%
  &:after
    left 42%
    top 20%
    height 60%
    width 16%
    transition height 200ms, top 200ms
  &.open
    &:after
      height 16%
      top 42%
  +stacked-layout()
    margin 0 $margin-base 0 0

.search-result-data
  font-size $font-size-h3
  word-break break-word
  &.grouped
    width calc(100% - 3rem)
  +column-layout()
    &.long-result
      .search-result-name
        font-size 1.8rem

.search-result-name,
.search-result-municipality
  display: inline
  font-size 1.8rem

.search-result-name
  .result-name-match
    color $color-blue-base
    font-weight bold
    font-size 105%

.error-on-search
  color red

.search-error-msg
  margin $margin-half $margin-base
  color white
  background-color $color-red-additional
  padding 0.3rem 0.5rem
  border-radius: 3px
  animation highlight-error 1s;

.search-results__button
  color $color-blue-base
  cursor pointer
  +column-layout()
    font-size $font-size-h3
  svg
    margin-left $margin-half

.search-results__show-all-on-map
  search-result-block__border()
  padding $margin-half 0
  &:focus-visible
    outline none
    box-shadow 0 0 0 3px $color-blue-base inset
  +column-layout()
    search-result__border()
  +stacked-layout()
    padding-left $margin-base + $grid-gutter

.search-no-results
  padding $margin-base

.search-result-actions
  search-result__border()
  color $search-result-text-color
  position relative
  +stacked-layout()
    font-size 1.8rem

.search-results__toggle-button
  color $color-orange-base
  display block
  width 100%
  transition background-color 300ms
  &:focus-visible
    outline none
    box-shadow 0 0 0 3px $color-orange-base inset
  +stacked-layout()
    float right
    background none
    border 0
    padding $margin-half 0
    font-size 2rem
  +column-layout()
    font-size 2rem

.search-results__toggle-block
  search-result-block__border()
    +column-layout()
      display none

.search-results__show-results-label
  padding-top $margin-half
  color $search-result-text-color
  text-align center

.search-results-count
  color $search-result-text-color
  +stacked-layout()
    padding-left $margin-base + $grid-gutter

.search-results-count__matches, .search-results-count__too-many
  margin 0 0 $margin-half 0

+column-layout()
  .search-results-count__matches,
  .search-results-count__too-many,
  .search-results__show-all-on-map
    padding-left 1rem

+pointer-states()
  .search-results__toggle-button
    &:hover
      background-color darken($search-results-bg-color, 10%)
