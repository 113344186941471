.scrollbar
  border-radius 3px
  cursor pointer
  background $color-blue-base
  z-index 10
  &:active
    background-color $color-blue-dark-1

.vertical-scrollbar,
.vertical-scroller
  z-index 1

.scrollbar-view
  z-index 0
  &#maincontent
    display grid
    grid-template-rows minmax(min-content, auto) min-content

.scrollbar-track
  z-index 10
  top 2px
  bottom 2px
  right 2px
  &:hover, &:active
    width 10px!important