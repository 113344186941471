$maptool-button-border-color = alpha($color-white, 0.3)
$maptool-button-disabled-bg-color = transparentify($color-grey-dark-3, 0.6)
$maptool-button-bg-color = $color-blue-base-darker
$maptool-button-bg-active-color = $color-blue-dark-2

$maptool-button-active-border-size = 0.3rem
$maptool-button-size = 5.6rem
$maptool-button-width = 7rem

$maptool-border = 1px solid $maptool-button-border-color

.maptools__toolbar
  +column-layout()
    width 4.5rem
    margin-top 1.5rem
  +stacked-layout()
    background-color $color-blue-base
.sidebar.open
  .maptools__toolbar
    width 28rem
    margin-top 0


/*
  btn-maptool
*/

.button.maptool__button
  width $maptool-button-width
  height $maptool-button-size - $maptool-button-active-border-size
  color $color-white
  background-color $maptool-button-bg-color
  border-width 0 1px $maptool-button-active-border-size 0
  border-color $maptool-button-bg-color $maptool-button-border-color $maptool-button-bg-color $maptool-button-bg-color
  border-radius 0
  box-shadow none
  +stacked-layout()
    width $maptool-button-size
  &.active
    background-color $maptool-button-bg-active-color
    border-color $maptool-button-bg-active-color $maptool-button-bg-active-color $color-orange-base
  &.disabled
    color $color-grey-dark-3
    background-color $maptool-button-disabled-bg-color
    pointer-events none
  &:focus-visible
    outline none
    box-shadow 0px 0px 0px 4px $color-white inset
  .glyphicon
    top 0.125rem
    width 60%
    height 60%
    path
      transform translateX(-6%)
  .glyphicon.fa-roskakori
    path
      transform translateX(-8%)

+pointer-states()
  .maptool__button
    pointer-button-states($maptool-button-bg-active-color, $color-white, $maptool-button-bg-active-color, $color-white)
  .button.maptool__button
    &.active:hover
      background-color $maptool-button-bg-active-color
      border-color $maptool-button-bg-active-color $maptool-button-bg-active-color $color-orange-base
    &:hover
      border-color $maptool-button-bg-active-color
