.map-site-index__row
  display flex
  align-items center
  padding $margin-base $margin-base * 4 $margin-base 0
  min-height 10rem
  .glyphicon
    color $color-text-base
    width 9rem
    height 100%
    padding 0 2rem
  .glyphicon.fa-download-open-data
    margin-top -0.9rem

.map-site-index__title
  @extend $h3
  color $color-heading-base
  margin-top 0

.map-site-index__text
  color initial

.map-site-index__link
  *
    &:hover
      text-decoration none
  &:focus
    outline none
    box-shadow 0px 0px 0px 3px $color-blue-base inset, -4px 0 0 3px $color-blue-base, 4px 0 0 3px $color-blue-base

