/*
* Glyphicon size modifiers
*/

.glyphicon--smaller
  font-size 75%

.glyphicon--larger
  font-size 125%

.glyphicon-lock
  font-size 130% !important