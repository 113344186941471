.date-picker-input-field__wrapper
  position relative
  .glyphicon
    position absolute
    top 0
    right $margin-base
    height 100%
    font-size 1.2em
    pointer-events none

  +max-screen($breakpoint-desktop)
    max-width 18rem

.DayPickerInput-OverlayWrapper
  position relative

.DayPickerInput-Overlay
  position absolute
  left 0
  z-index 1
  background $color-white
  box-shadow 0 2px 5px rgba(0, 0, 0, 0.15)
  border-radius 3px

@import "react-day-picker/dist/style.css"

:root {
  --rdp-accent-color: $color-blue-dark-1
  --rdp-background-color: $color-blue-dark-2
}

.rdp
  margin 0

.rdp-table
  margin 0 2em 2em 2em

.rdp-day
  border-radius 0

.rdp-cell
  border 1px solid $color-grey-light-3

.rdp-caption
  padding 0.5em
  background-color $color-blue-dark-2
  color $color-white

.rdp-caption_dropdowns
  width 100%

.rdp-dropdown_month,
.rdp-dropdown_month
  flex-grow 1
  margin-left 1em

.rdp-head_cell
  color $color-blue-dark-2

.rdp-dropdown
  color $color-white
  padding-left 2 * $margin-base
  font-weight bold
  border none
  appearance none
  background-color transparent
  cursor pointer
  font-size 1.8rem
  option
    color $color-black

.rdp-button:focus:not([disabled])
  color white

.rdp-button:hover:not([disabled])
  background-color $color-blue-light-3
  color $color-blue-dark-2

.rdp-day_selected
  background-color $color-blue-dark-1

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label
  border-color $color-blue-dark-1
  background-color $color-blue-dark-2
