.beta-badge
  background-color $color-yellow-base
  color $color-black
  font-size 1.5rem
  margin-left 1rem
  padding-left 0.5rem
  padding-right 0.5rem

.nls-badge
  background-color $color-orange-base
  border-radius 100%
  color white
  display inline-flex
  font-size 1.125rem
  justify-content center
  align-items center
  width 2rem
  height 2rem
