@require '~rc-slider/assets/'

.rc-slider-rail, .rc-slider-track, .rc-slider-mark
  display none

.rc-slider-disabled
  background-color inherit

.rc-slider-vertical
  width inherit 
